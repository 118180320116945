import React, {Component} from "react";
import styled from "styled-components";
import {COLOR_RUN, COLOR_SETUP, COLOR_WAIT} from "./StepTimePreview";

interface StepTimeCircleProps {
    waitMinutes: number;
    setupMinutes: number;
    runMinutes: number

}

interface CircleBackgroundProps {
    size: number;
    borderWidth: number;
}


const CircleBackGround = styled.div<CircleBackgroundProps & StepTimeCircleProps & { totalMinutes: number, colorWait: string, colorSetup: string, colorRun: string }>`
    width: ${props => props.size}px !important;
    height: ${props => props.size}px !important;
    border-radius: 50%;
    background: conic-gradient(${props => props.colorWait} 0deg,
    ${props => props.colorWait} ${props => props.waitMinutes / props.totalMinutes * 360}deg,
    ${props => props.colorSetup} ${props => props.waitMinutes / props.totalMinutes * 360}deg,
    ${props => props.colorSetup} ${props => (props.setupMinutes + props.waitMinutes) / props.totalMinutes * 360}deg,
    ${props => props.colorRun} ${props => (props.setupMinutes + props.waitMinutes) / props.totalMinutes * 360}deg,
    ${props => props.colorRun} 360deg);
    padding: ${props => props.borderWidth}px;

`;

const CircleContent = styled.div<CircleBackgroundProps>`
    width: ${props => props.size - 2 * props.borderWidth}px !important;
    height: ${props => props.size - 2 * props.borderWidth}px !important;
    border-radius: 50%;
    background-color: white;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: ${props => props.size - 2 * props.borderWidth}px;
`;
export default class StepTimeCircle extends Component<StepTimeCircleProps> {
    render() {
        const {waitMinutes, setupMinutes, runMinutes} = this.props;

        const totalMinutes = waitMinutes + setupMinutes + runMinutes;

        const circleProps = {
            size: 130,
            borderWidth: 10
        };

        return (
            <CircleBackGround {...circleProps} {...this.props} totalMinutes={totalMinutes}
                              colorWait={COLOR_WAIT} colorSetup={COLOR_SETUP} colorRun={COLOR_RUN}>
                <CircleContent  data-test-step-duration-total {...circleProps}>
                    {totalMinutes} min
                </CircleContent>

            </CircleBackGround>
        )
    }
}
