import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { Integration } from './Integration'


export class ShippingUnit extends Model {
  static backendResourceName = 'shipping_unit'

  @observable id = null
  @observable code = ''
  @observable description = ''

  @observable length = null
  @observable width = null
  @observable height = null
  @observable weight = null

  relations() {
    return {
        carrier: Integration
    }
  }
}

export class ShippingUnitStore extends Store {
  static backendResourceName = 'shipping_unit'
  Model = ShippingUnit
}
