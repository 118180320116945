import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { SectionStore } from 'store/Section'
import Sections from 'component/Sections'
import { Button, Popup, Tab, Modal, Table, Icon } from 'semantic-ui-react'
import { isFeatureFlagEnabled } from 'helpers/featureFlags'
import RightDivider from 'spider/component/RightDivider'
import { ExactDocument } from 'store/Exact/Document'
import { ProductionRequest } from 'store/ProductionRequest'
import { Metavalues } from 'component/Metafields'


const InstructionContainer = styled.div`
  grid-area: Instructions;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: start;
  min-width: 0;
`

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  row-gap: 2rem;
  margin-left: 10px;
  align-items: fex-start;
`

const TabPaneStyled = styled(Tab.Pane)`
  height: calc(100% - 126px);
`

@observer
export default class Instructions extends Component {
  static propTypes = {
    sections: PropTypes.instanceOf(SectionStore).isRequired,
    open: PropTypes.bool,
    notes: PropTypes.string,
    synergyDocumentIds: PropTypes.array,
    documents: PropTypes.instanceOf(ExactDocument),
    productionRequest: PropTypes.instanceOf(ProductionRequest),
  }

  static defaultProps = {
    open: false,
    notes: '',
    documents: [],
    synergyDocumentIds: [],
  }

  constructor(...args) {
    super(...args)

    this.renderTabs = this.renderTabs.bind(this)
    this.renderInstructions = this.renderInstructions.bind(this)
    this.renderDocumentsView = this.renderDocumentsView.bind(this)
    this.renderNotes = this.renderNotes.bind(this)
  }

  @observable open = this.props.open
  @observable fullScreen = null
  @observable freeFields = false

  renderInstructions() {
    const { sections } = this.props

    return (
      <Sections
        style={{ height: '100%', overflowY: 'scroll' }}
        sections={sections}
        showImportant={true}
      />
    )
  }

  renderDocumentsView() {
    const { synergyDocumentIds, documents } = this.props

    if (isFeatureFlagEnabled('synergy')) {
      const iframes = []
      // eslint-disable-next-line
      for (const docId of synergyDocumentIds) {
        iframes.push(
          <div dangerouslySetInnerHTML={{
            __html: `<iframe width='100%' height='100%' scrolling='yes' src='https://synergy.agmi.nl/synergy/docs/DocView.aspx?DocumentID=%7b${docId}%7d' sandbox='allow-modals allow-forms allow-popups allow-scripts allow-same-origin'></iframe>`
          }}
          />
        )
      }

      return <>{iframes}</>
    } else {
      console.log(documents)
      return (
        <Table compact data-test-order-documents>
          <Table.Header>
            <Table.HeaderCell>{t('workStation.production.performModal.instructions.document.id')}</Table.HeaderCell>
            <Table.HeaderCell>{t('workStation.production.performModal.instructions.document.description')}</Table.HeaderCell>
            <Table.HeaderCell>{t('workStation.production.performModal.instructions.document.subject')}</Table.HeaderCell>
            <Table.HeaderCell>{t('workStation.production.performModal.instructions.document.date')}</Table.HeaderCell>
            <Table.HeaderCell><Icon name='external' /></Table.HeaderCell>
          </Table.Header>
          <Table.Body>
            {documents.models.map((doc) => (
              <Table.Row>
                <Table.Cell collapsing>{doc.id}</Table.Cell>
                <Table.Cell collapsing>{doc.typeDescription}</Table.Cell>
                <Table.Cell collapsing>{doc.documentSubject}</Table.Cell>
                <Table.Cell collapsing>{doc.documentDate}</Table.Cell>
                <Table.Cell collapsing>
                  <Button data-test-document-link
                    icon={'exact'}
                    href={doc.documentViewUrl}
                    target="_blank"
                  />
                </Table.Cell>
              </Table.Row>
            )
            )}
          </Table.Body>
        </Table>
      )
    }

  }

  renderNotes() {
    const { notes } = this.props
    if (!notes) {
      return 'No notes.'
    }
    return <span>{notes}</span>
  }

  renderTabs() {
    const { notes, sections, synergyDocumentIds, documents, productionRequest } = this.props

    const articleType = productionRequest.processVersion.batchType.articleType

    const showInstructions = sections.length > 0
    const showSynergy = synergyDocumentIds.length > 0 || documents.length > 0
    const showNotes = notes !== null && notes.length > 0
    const showMetavalues = articleType.metavalues.length > 0

    let defaultActiveIndex = 0
    if (showInstructions) {
      defaultActiveIndex = 0
    } else if (showSynergy) {
      defaultActiveIndex = 1
    } else if (showNotes) {
      defaultActiveIndex = 2
    } else if (showMetavalues) {
      defaultActiveIndex = 3
    }

    return (
      <Tab data-test-instuction-tabs
        menu={{ secondary: true, pointing: true }}
        defaultActiveIndex={defaultActiveIndex}
        panes={[
          showInstructions && { menuItem: 'Instructions', render: () => <TabPaneStyled attached={false}>{this.renderInstructions()}</TabPaneStyled> },
          showSynergy && { menuItem: 'Order documents', render: () => <TabPaneStyled attached={false}>{this.renderDocumentsView()}</TabPaneStyled> },
          this.freeFields && { menuItem: 'Free Fields', render: () => <TabPaneStyled attached={false}></TabPaneStyled> },
          showMetavalues && { menuItem: 'Meta Values', render: () => <TabPaneStyled attached={false}> <Metavalues model={articleType} disabled={true} /> </TabPaneStyled>},
          showNotes && { menuItem: 'Notes', render: () => <TabPaneStyled attached={false}>{this.renderNotes()}</TabPaneStyled> },
        ]}
      />
    )
  }

  renderFullScreenModal() {
    return (
      <Modal data-test-full-screen-modal open closeIcon size="large" onClose={() => this.fullScreen = null}>
        <Modal.Header>{t('workStation.production.performModal.instructions.title')}</Modal.Header>
        <Modal.Content scrolling>
          {this.renderTabs()}
        </Modal.Content>
        <Modal.Actions>
          <RightDivider />
          <Button primary
            icon="check"
            content={t('workStation.production.performModal.instructions.doneBtn')}
            onClick={() => this.fullScreen = null}
          />
        </Modal.Actions>
      </Modal>
    )
  }

  render() {
    const { sections } = this.props

    return (
      <InstructionContainer>
        <Buttons>
          {!this.open && (
            <Popup
              content={t('workStation.production.performModal.instructions.noInstructions')}
              disabled={sections.length > 0 || !this.open}
              trigger={
                <Button data-test-open-instructions primary
                  icon="info circle"
                  size="massive"
                  onClick={() => this.open = !this.open}
                />
              }
            />
          )}
          {this.open && (
            <>
              <Button primary
                icon="close"
                size="massive"
                onClick={() => this.open = !this.open}
              />
              <Button primary data-test-instructions-full-screen
                icon="expand"
                size="massive"
                onClick={() => { this.fullScreen = true }}
              />
            </>
          )}
        </Buttons>
        {this.open && this.renderTabs()}
        {this.fullScreen && this.renderFullScreenModal()}
      </InstructionContainer>
    )
  }
}
