import React from 'react';
import {Route} from "react-router-dom";
import Load from "container/Loadable";

const CapacityOverview = Load(() => import('./screen/CapacityOverview'))

export default (routeCallBack) => {
  return (
    <>
      <Route path="/planning/capacity/:period?" render={routeCallBack(CapacityOverview)}/>
    </>
  )
}
