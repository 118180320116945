import {Modal} from 'semantic-ui-react';
import React, {Component} from "react";
import {observer} from 'mobx-react';


interface MultiActionPerformModalProps {
    action: JSX.Element | null;

    /**
     * Callback for when onClose event is triggered
     */
    onClose: () => void;
}

@observer
export class MultiActionPerformModal extends Component<MultiActionPerformModalProps> {

    render() {
        const {action, onClose} = this.props
        return (
            <Modal 
                open={action !== null}
                closeOnDimmerClick
                closeIcon
                onClose={onClose}
                data-test-multi-action-modal
            >
                <Modal.Header>Perform multi-action</Modal.Header>
                <Modal.Content image>
                    <Modal.Description>
                        {action}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                </Modal.Actions>
            </Modal>
        )
    }


}
