import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {observer} from 'mobx-react'
import {Step} from 'store/Step'
import {MetafieldsContext} from 'component/Metafields'
import {BatchType} from '../../../store/BatchType'
import {TargetSelect} from "../../../spider/semantic-ui/Target";
import {IntegrationStore} from "../../../store/Integration";


@observer
export default class ImportStepEdit extends Component {
  static propTypes = {
    step: PropTypes.instanceOf(Step).isRequired,
    steps: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    machineEnabled: PropTypes.bool,
    batchEnabled: PropTypes.bool,
    batchType: PropTypes.instanceOf(BatchType).isRequired,
    variable: PropTypes.shape({
      before: PropTypes.bool.isRequired,
      after: PropTypes.bool.isRequired,
    }).isRequired,
  }


  static contextType = MetafieldsContext

  integrationStore = new IntegrationStore();


  render() {
    const {step} = this.props;

    return (
      <React.Fragment>
        <TargetSelect
          remote
          label={t('importStep.field.integration.name')}
          target={step.importStep}
          name="integration"
          store={this.integrationStore}
          toOption={integration => ({value: integration.id, text: integration.name})}
        />
      </React.Fragment>
    )
  }
}
