// Currently contains some of the part of the form step
// Idea is to move everything here at some point, to structure it properly
import React, { Component } from "react";
import { BillOfMaterialVersion } from "store/BillOfMaterialVersion";
import PropTypes from "prop-types";
import { observable } from "mobx";
import { observer } from 'mobx-react'
import { Checkbox, Icon } from 'semantic-ui-react'
import styled from "styled-components";
import { ErrorLabel } from 'spider/semantic-ui/Target'
import { multiplyDecimals } from 'helpers/decimal'


export const FullWidthTable = styled.table`
  width: 100%;
  table-layout: fixed;

  th, td {
    overflow-wrap: break-word;
  }
`;

/**
 * Checks if an materialPlanItem should be shown for this workstation.
 * Returns true if:
 * - item is assigned to this workstation
 * - item is not assigned to any workstation (as we cannot be sure it is not important)
 * - item is assigned to a workstation in exact, but the exact work station is not linked to a tracy workstation. In that
 * case it is also not known if we should or shouldn't show it, so we show it.
 *
 * @param workStation
 * @param materialPlanItem
 */
const isMyWorkstation = (workStationCode, materialPlanItem) => {
  if (materialPlanItem.externalWorkStation.isNew) {
    return true;
  }

  if (materialPlanItem.externalWorkStation.workStation.isNew) {
    return true;
  }

  return materialPlanItem.externalWorkStation.workStation.code === workStationCode;
}

/**
 * Contains the actual table of the material plan to be executed
 */
@observer
class MaterialPlanTable extends Component {
  static propTypes = {
    materialPlan: PropTypes.instanceOf(BillOfMaterialVersion).isRequired,
    workStationCode: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.number.isRequired,
    quantityTodo: PropTypes.number.isRequired,
    value: PropTypes.object.isRequired,
    errors: PropTypes.array,
    batchSize: PropTypes.number,
  }

  static defaultProps = {
    errors: [],
    batchSize: 1,
  }

  constructor(...args) {
    super(...args)

    this.renderMaterialPlan = this.renderMaterialPlan.bind(this)
  }

  renderMaterialPlan(materialPlanItem) {
    const { materialPlan, onChange, type, required, value, workStationCode } = this.props;

    const quantity = materialPlanItem.requiredQuantity ?? multiplyDecimals(materialPlanItem.quantityBatch ?? materialPlanItem.quantity, required)
    //TODO: double check this calcultaion: Math.ceil((materialPlan.defaultBatchQuantity || 1) * materialPlanItem.quantity * batchSize)
    const itemQt = type === 'task' ? `${Math.ceil(quantity)} Minutes` : quantity * 1
    let itemChecked = value[materialPlanItem.articleType.id] || false
    if (!itemChecked && type === 'material' && materialPlanItem.backflush) {
      itemChecked = true
    }
    return (
      <tr>
        <td>{materialPlanItem.articleType.code || '-'}</td>
        <td data-test-required-quantity={materialPlanItem.id} colspan={0}>{itemQt}</td>
        <td>Code {materialPlanItem.articleType.name}</td>
        <td>{materialPlanItem.backflush ? <Icon name="check" /> : ''}</td>
        <td>
          <Checkbox toggle data-test-materials-item={materialPlanItem.id}
            checked={itemChecked}
            disabled={type === 'material' && materialPlanItem.backflush}
            onChange={(e, { checked }) => {
              let itemsFinished = null
              // Check bom items finished
              const items = materialPlan.items.filter(item => item.type === type && isMyWorkstation(workStationCode, item))
              // eslint-disable-next-line
              for (const item of items) {
                if (item.id === materialPlanItem.id && checked) {
                  continue
                }
                if (value[item.articleType.id] === undefined || !value[item.articleType.id]) {
                  itemsFinished = false
                  break
                }
              }
              if (itemsFinished === null) {
                itemsFinished = true
              }
              onChange(materialPlanItem, checked, itemsFinished)
            }}
          />
        </td>
        <td data-test-done={materialPlanItem.id}>{itemChecked ? `${quantity}/${quantity}` : ''}</td>
      </tr>
    )
  }

  render() {
    const { materialPlan, workStationCode, type, errors } = this.props;
    const generalErrors = errors.filter(({ path }) => path.length === 0)

    return (
      <FullWidthTable data-material-plan-table={type}>
        <thead>
          <th>{t(`formStepField.field.materialPlan.code.label`)}</th>
          <th colspan={0}>{type === 'material' ? t(`formStepField.field.materialPlan.requiredQuantity.label`) : t(`formStepField.field.materialPlan.requiredTime.label`)}</th>
          <th>{t(`formStepField.field.materialPlan.description.label`)}</th>
          <th>{t(`formStepField.field.materialPlan.backflush.label`)}</th>
          <th>{t(`formStepField.field.materialPlan.checklist.label`)}</th>
          <th>{t(`formStepField.field.materialPlan.finished.label`)}</th>
        </thead>
        <tbody>
          {materialPlan.items
            .filter(item => item.type === type && isMyWorkstation(workStationCode, item))
            .map(this.renderMaterialPlan)}
        </tbody>
        {generalErrors.length > 0 && (
          <ErrorLabel>
            {generalErrors.map(({ message }, i) => <div key={i}>{message}</div>)}
          </ErrorLabel>
        )}
      </FullWidthTable>
    )
  }
}

/**
 * Adapter which shows the MaterialPLan in a nice way in the form fields
 */
@observer
export class MaterialPlan extends Component {
  static propTypes = {
    materialPlan: PropTypes.instanceOf(BillOfMaterialVersion),
    workStationCode: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    colspan: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    quantityTodo: PropTypes.number.isRequired,
    required: PropTypes.number.isRequired,
    value: PropTypes.object.isRequired,
    errors: PropTypes.array,
    batchSize: PropTypes.number,
  }

  // VBH alert, because of some weird filtering 5 levels up, the material plan does not contain all material plan lines
  // So, make a local copy, and do another fetch.
  @observable localMaterialPlan

  constructor(props) {
    super(props);

    this.localMaterialPlan = new BillOfMaterialVersion({ id: props.materialPlan?.id },
      { relations: ['items.articleType', 'items.externalWorkStation.workStation'] },
    )

  }

  componentDidMount() {
    if (!this.localMaterialPlan.isNew) {
      this.localMaterialPlan.fetch()
    }
  }


  render() {
    const { workStationCode, type, colspan, onChange, errors, batchSize, quantityTodo, required, value } = this.props

    if (this.localMaterialPlan.isNew) {
      return (
        <tr>
          <td>{t(`formStepField.field.materialPlan.${type}s`)}</td>
          <td data-test-material-plan-not-available={type}>{t(`formStepField.field.materialPlan.notAvailable`)}</td>
        </tr>
      )
    }
    return (
      <tr>
        <td colspan={colspan}>
          <MaterialPlanTable
            materialPlan={this.localMaterialPlan}
            type={type}
            workStationCode={workStationCode}
            value={value}
            onChange={onChange}
            errors={errors}
            required={required}
            batchSize={batchSize}
            quantityTodo={quantityTodo}
          />
        </td>
      </tr>
    )
  }
}

