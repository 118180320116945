import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import {ArticleType} from "./ArticleType";

export class NestType extends Model {
  static backendResourceName = 'nest_type'

  @observable id = null
  @observable name = ''


  relations() {
    return {
      articleType: ArticleType
    }
  }

}

export class NestTypeStore extends Store {
  Model = NestType
  static backendResourceName = 'nest_type'

}
