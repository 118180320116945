import {observable} from 'mobx'
import {Model, Store} from 'store/Base'
import {NestType} from "./NestType";
import { ProductionRequest } from './ProductionRequest';

/**
 * Nests should be mostly immutable from the frontend perspective,
 *
 * - All logic is done on the linked production request
 * - Creation does a lot of logic on the backend, and has it's own factory method (Create)
 */
export class Nest extends Model {
  static backendResourceName = 'nest'


  @observable id = null

  relations() {
    return {
      nestType: NestType,
      productionRequest: ProductionRequest,
    }
  }

  save(options = {}) {
    throw Error('Immutable object cannot be saved')
  }

  create = async (batchStore) => {
    const data = this.toBackend();

    data['batches'] = batchStore.map(pr => pr.id)
    // data['nest_type'] = this.nestType?.id;

    await this.__getApi()
      .saveModel({
        url: `${this.url}create/`,
        data: data,
        isNew: true,
      })
  }

}

export class NestStore extends Store {
  Model = Nest
  static backendResourceName = 'nest'

}
