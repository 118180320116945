export const DURATION_MILLISECOND = 'millisecond';
export const DURATION_SECOND = 'second';
export const DURATION_MINUTE = 'minute';
export const DURATION_HOUR = 'hour';
export const DURATION_DAY = 'day';


const DURATION_UNITS = {
  [DURATION_MILLISECOND]: {display: 'ms', seconds: 0.001, next: DURATION_SECOND},
  [DURATION_SECOND]: {display: 's', seconds: 1, next: DURATION_MINUTE, previous: DURATION_MILLISECOND},
  [DURATION_MINUTE]: {display: 'm', seconds: 60, next: DURATION_HOUR, previous: DURATION_SECOND},
  [DURATION_HOUR]: {display: 'h', seconds: 60 * 60, next: DURATION_DAY, previous: DURATION_MINUTE},
  [DURATION_DAY]: {display: 'd', seconds: 24 * 60 * 60, previous: DURATION_HOUR},
}


/**
 *
 *
 * @param duration InputDuration
 * @param unit Unit of the input duration.
 * @param maxUnit Maximum unit to display (e.g. if hours, 36 hours won't be converted to 1 day, 12 hours)
 * @param minUnit Minimum unit to display
 * @param sep seperation between different units
 * @param showAllUnits also show leading units that are zero (e.g. 0d0h10m)
 * @returns {string}
 */
export function formatDuration(duration, {unit = 'second', maxUnit = null, minUnit = DURATION_MINUTE, sep = ' ', showAllUnits = false } = {}) {
  if (isNaN(parseFloat(duration)) ) {
    return '-';
  }


  const options = {unit, maxUnit, minUnit, sep, showAllUnits};

  // IN case we have a negative duration, negate, and go again
  if (duration < 0) {
    return `-${sep}${formatDuration(-duration, {...options})}`
  }

  // Normalize the input unit always to seconds
  if (unit === DURATION_MILLISECOND) {
    return formatDuration(duration / 1000, {...options})
  }
  if (unit !== DURATION_SECOND) {
    const currentDuration = DURATION_UNITS[unit]

    return formatDuration(duration * currentDuration.seconds, { ...options, unit: DURATION_SECOND})
  }

  // If no maxunit and minute are not set, we set it to the max and min respectively
  if (maxUnit === null) {
    maxUnit = DURATION_DAY;
  }

  if (minUnit === null) {
    minUnit = DURATION_MILLISECOND;
  }

  // Base CAse, if max Unit and minunit are the same

  const maxUnitDetails = DURATION_UNITS[maxUnit]

  if (maxUnit === minUnit) {
    return `${Math.round(duration / maxUnitDetails['seconds'])}${maxUnitDetails['display']}`
  }

  // Check if we need to do something with this max unit
  if (maxUnitDetails.seconds > duration && !showAllUnits) {
    return formatDuration(duration, {...options, maxUnit: maxUnitDetails.previous })
  }

  const durationUnits = Math.floor(duration / maxUnitDetails.seconds);
  const remainingSeconds = duration % maxUnitDetails.seconds;

  const prefix = `${durationUnits}${maxUnitDetails['display']}`;

  // If we have formatted everything
  if (!remainingSeconds) {
    return prefix
  }

  const postfix = formatDuration(remainingSeconds, {...options, maxUnit: maxUnitDetails.previous, showAllUnits: true})

  return `${prefix}${sep}${postfix}`
}
