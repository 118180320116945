import React from 'react';
import {observer} from "mobx-react";
import {Integration, TYPE_INTEGRATION_RADAN} from "../../../store/Integration";
import RadanExportStep from "./Export/Radan";
import {PerformMultiStepComponent} from "feature/processStep/interface";



@observer
export default class PerformMultiExportStep extends PerformMultiStepComponent {
    /**
     * Get the content depending on the type of integration
     */
    getIntegrationContent = () => {
        const {steps, batches, operator, afterPerform} = this.props;
        // @ts-ignore
        const integration: Integration = steps.keys().next().value?.exportStep?.integration

        if (!integration) {
            return (<></>)
        }

        if (integration.type === TYPE_INTEGRATION_RADAN) {
            return (
                <RadanExportStep
                    integration={integration}
                    batches={batches}
                    steps={steps}
                    operator={operator}
                    afterPerform={afterPerform}
                />
            )
        }


        return (<>No bulk actions possible for integration of type {integration.type}</>);
    }

    render() {
        const content = this.getIntegrationContent();
        return (
            <>
                {content}
            </>
        )
    }

}
