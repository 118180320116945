import React, {Component} from "react";
import {Table} from "semantic-ui-react";
import {TIME_FORMAT} from "../../../../helpers";
import {MetaData, ProcessData, StepDatum} from "./Interface";
import {observable} from "mobx";
import {createXScale} from "./xScale";
import {ScaleTime} from "d3-scale";
import {observer} from "mobx-react";
import StepRow from "./StepRow";
import styled from "styled-components";
import ProcessRow from "./ProcessRow";
import {Difference} from "./Difference";


const StyledTable = styled(Table)`
    td {
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

const ProcessCell = styled(Table.Cell)`
    background-color: #212121;
    color: white;
`;

@observer
export default class TimingTable extends Component<{
    steps: StepDatum[],
    processData: ProcessData,
    metaData: MetaData
}> {

    // Hardd coded for now
    visualizationWidth = 1200;


    @observable scaleX: null | ScaleTime<number, number> = null;


    componentDidMount() {
        const {metaData} = this.props;
        this.scaleX = createXScale(
            this.visualizationWidth,
            metaData
        )
    }


    renderRow = (step: StepDatum) => {
        if (!this.scaleX) {
            return (<></>)
        }

        const {processData} = this.props;
        return (
            <Table.Row height={25}>
                <Table.Cell>
                    {step.name}
                </Table.Cell>
                <Table.Cell>
                    {step.workStation}
                </Table.Cell>
                <Table.Cell>
                    {step.plannedEnd.format('DD-MM ' + TIME_FORMAT)}
                </Table.Cell>
                <Table.Cell>
                    {step.actualEnd?.format('DD-MM ' + TIME_FORMAT)}
                </Table.Cell>
                <Table.Cell>
                    {step.actualStart && (
                        <Difference
                            planned={step.plannedStart}
                            actual={step.actualStart}
                        />
                    )}
                </Table.Cell>
                <Table.Cell>
                    <StepRow
                        scaleX={this.scaleX}
                        stepDatum={step}
                        processData={processData}
                    />
                </Table.Cell>
            </Table.Row>
        )
    }

    render() {
        if (!this.scaleX) {
            return (
                <>
                </>
            )
        }
        const {steps, processData} = this.props;
        return (
            <StyledTable>
                <Table.Header>
                    <Table.HeaderCell colSpan={5}>

                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        {/*<DateHeader*/}
                        {/*    scaleX={this.scaleX}*/}
                        {/*/>*/}
                    </Table.HeaderCell>

                </Table.Header>
                <Table.Header>
                    <Table.HeaderCell>
                        Step name
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Workstation
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Planned End
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Actual End
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Difference
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{width: '100%'}}>

                    </Table.HeaderCell>
                </Table.Header>
                <Table.Row>
                    <ProcessCell>
                        {processData.name}
                    </ProcessCell>
                    <ProcessCell>
                    </ProcessCell>
                    <ProcessCell>
                        {processData.plannedEndAt.format('DD-MM ' + TIME_FORMAT)}
                    </ProcessCell>
                    <ProcessCell>
                        {processData.actualEndAt?.format('DD-MM ' + TIME_FORMAT)}
                    </ProcessCell>
                    <ProcessCell>
                        {processData.actualStartAt && (
                            <Difference
                                planned={processData.plannedStartAt}
                                actual={processData.actualStartAt}
                            />
                        )}
                    </ProcessCell>
                    <Table.Cell>
                        <ProcessRow
                            scaleX={this.scaleX}
                            processData={processData}
                        />
                    </Table.Cell>
                </Table.Row>
                {steps.map(this.renderRow)}
            </StyledTable>
        )
    }
}
