import { observable } from 'mobx'
import { Store } from 'store/Base';
import { CarrierIntegration } from './CarrierIntegration';
import { Integration } from './Integration';
import { ShippingUnitStore } from './ShippingUnit';

export class UpsIntegration extends CarrierIntegration {
    static backendResourceName = 'ups_integration';
    static omitFields = ['active'];

    @observable id = null;
    @observable active = false;

    @observable apiKey = "";
    @observable username = null;
    @observable password = null;
    @observable accountNumber = null;
    
    @observable senderName = "";
    @observable senderAddress = "";
    @observable senderHouseNumber = "";
    @observable senderCountryCode = "";
    @observable senderZipCode = "";
    @observable senderCity = "";
    @observable senderPhoneNumber = null;

    // UPS paperless enabled:
    @observable userFormsEnabled = true;

    relations() {
      return {
        integration: Integration,
        shippingUnits: ShippingUnitStore,
      }
    }

}

export class UpsIntegrationStore extends Store {
    static backendResourceName = 'ups_integration';
    Model = UpsIntegration;
}

// Array of all services offered by UPS as desribed in the UPS ship api documentation
// https://www.ups.com/upsdeveloperkit/downloadresource?loc=en_NL
export const upsServices = [
  {
    value: '01',
    text: "Next Day Air",
  },
  {
    value: '02',
    text: "2nd Day Air",
  },
  {
    value: '03',
    text: "Ground",
  },
  {
    value: '07',
    text: "Express",
  },
  {
    value: '08',
    text: "Expedited",
  },
  {
    value: '11',
    text: "UPS Standard",
  },
  {
    value: '13',
    text: "3 Day Select",
  },
  {
    value: '14',
    text: "UPS Next Day Air® Early",
  },
  {
    value: '17',
    text: "UPS Worldwide Economy DDU",
  },
  {
    value: '54',
    text: "Express Plus",
  },
  {
    value: '59',
    text: "2nd Day Air A.M.",
  },
  {
    value: '65',
    text: "UPS Saver",
  },
  {
    value: 'M2',
    text: "First Class Mail",
  },
  {
    value: 'M3',
    text: "Priority Mail",
  },
  {
    value: 'M4',
    text: "Expedited MaiI Innovations",
  },
  {
    value: 'M5',
    text: "Priority Mail Innovations",
  },
  {
    value: 'M6',
    text: "Economy Mail Innovations",
  },
  {
    value: 'M7',
    text: "MaiI Innovations (MI) Returns",
  },
  {
    value: '70',
    text: "UPS Access Point™ Economy",
  },
  {
    value: '71',
    text: "UPS Worldwide Express Freight Midday",
  },
  {
    value: '72',
    text: "UPS Worldwide Economy DDP",
  },
  {
    value: '74',
    text: "UPS Express®12:00",
  },
  {
    value: '82',
    text: "UPS Today Standard",
  },
  {
    value: '83',
    text: "UPS Today Dedicated Courier",
  },
  {
    value: '84',
    text: "UPS Today Intercity",
  },
  {
    value: '85',
    text: "UPS Today Express",
  },
  {
    value: '86',
    text: "UPS Today Express Saver",
  },
  {
    value: '96',
    text: "UPS Worldwide Express Freight",
  },
]

// Array of all document types you can upload to ups form history (paperless api)
export const upsDocumentTypes = [
  {
    value: '001',
    text: "Authorization Form",
  },  
  {
    value: '002',
    text: "Commercial Invoice",
  },
  {
    value: '003',
    text: "Certificate of Origin",
  },
  {
    value: '004',
    text: "Export Accompanying Document",
  },
  {
    value: '005',
    text: "Export License",
  },
  {
    value: '006',
    text: "Import Permit",
  },
  {
    value: '007',
    text: "One Time NAFTA",
  },
  {
    value: '008',
    text: "Other Document",
  },
  {
    value: '009',
    text: "Power of Attorney",
  },
  {
    value: '010',
    text: "Packing List",
  },
  {
    value: '011',
    text: "SED Document",
  },
  {
    value: '012',
    text: "Shipper's Letter of Instruction",
  },
  {
    value: '013',
    text: "Declaration",
  },
]