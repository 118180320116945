import { Model, Store } from 'store/Base'
import { Integration } from '../Integration'
import { observable } from 'mobx'
import {Metafield} from "../Metafield";
import { showSaveNotification, showNotification } from '../../helpers/notification';

export class RadanIntegration extends Model {
  static backendResourceName = 'radan_integration';
  static omitFields = ['active'];

  @observable id = null;
  @observable active = false;
  @observable nextNestNumber = 0;

  relations() {
    return {
      integration: Integration,
      symbolMetafield: Metafield,
    };
  }

  createExportXml = async (batchStore) => {
    const data = {
      'batches': batchStore.map(pr => pr.id)
    }

    return await this.__getApi()
      .post(
        `${this.url}export_xml/`,
        data,
        {}
      )
  }

  importXml = async (operator, formData) => {
    formData.append('operator', operator.id)
    try {
      await this.__getApi()
      .post(
        `${this.url}import_xml/`,
        formData,
        {}
      )
      showSaveNotification()
    } catch (error) {
      this.handleImportErrors(error)
    }
  }

  handleImportErrors(superError) {
    if (!superError.response || !superError.response.data.errors || !superError.response.data.errors['radan_integration']) {
      return
    }

    const errors = superError.response.data.errors['radan_integration'][1]['integration']
    const code = superError.response.data.code
    const status = superError.response.status
    if (code !== 'ValidationError' || status !== 400) {
      return
    }

    errors.forEach(error => {
      showNotification({
        key: error.code,
        error: true,
        dismissAfter: 4000,
        message: error.message,
        icon: 'exclamation triangle',
      })
    })
  }

  validateXml = async (formData) => {
    try {
      const validationResult = await this.__getApi()
      .post(
        `${this.url}validate_xml/`,
        formData,
        {}
      )
      return validationResult
    } catch (error) {
      return error.response
    }
  }
}

export class RadanIntegrationStore extends Store {
  static backendResourceName = 'radan_integration'
  Model = RadanIntegration
}
