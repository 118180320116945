import React, { Component } from "react";
import PropTypes from "prop-types";
import { observable } from "mobx";
import { RadanIntegration, RadanIntegrationStore } from "store/Workstation/RadanIntegration";
import { observer } from "mobx-react";
import { DownloadButton, SyncButton } from "spider/semantic-ui/Button";
import { t } from "i18n";
import { Divider, Icon, Popup } from "semantic-ui-react";
import { Operator } from "../../store/Operator";


const VALIDATED_ICON = {
  true: 'checkmark',
  false: 'close',
  null: 'question',
}

const VALIDATED_TEXT = {
  true: t('radan.import.valid'),
  false: t('radan.import.invalid'),
  null: t('radan.import.needsValidation'),
}

const VALIDATED_COLOR = {
  true: 'green',
  false: 'red',
  null: 'black',
}


@observer
class RadanImportForm extends Component {
  static propTypes = {
    radanIntegration: PropTypes.instanceOf(RadanIntegration).isRequired,
    operator: PropTypes.instanceOf(Operator).isRequired,
    afterUpload: PropTypes.func.isRequired,
  }

  fileInputRef = React.createRef();
  
  @observable uploadModel = null
  @observable selectedFiles = []
  @observable validated = {}
  @observable validationError = ''
  

  uploadXml = async () => {
    const { radanIntegration, afterUpload, operator } = this.props;
    const formData = new FormData()
    this.selectedFiles.forEach(file => formData.append(file.name, file))

    // Note that Radan Integration is currently stateless, meaning that we do not have to fetch extra data right now
    await radanIntegration.importXml(operator, formData)

    afterUpload()
  }


  validateXml = async () => {
    const { radanIntegration } = this.props
    const formData = new FormData()
    this.selectedFiles.forEach(file => formData.append(file.name, file))

    this.validationError = ''
    const response = await radanIntegration.validateXml(formData)
    if (!response['validated']) {
      this.validationError = response.statusText
    } else {
      this.validated = response['validated']
    }
  }


  isAllValid() {
    return Object.values(this.validated).every(validation => validation === true) && Object.values(this.validated).length > 0
  }


  anyInvalid() {
    return Object.values(this.validated).some(validation => validation === false)
  }


  filesChange = e => {
    const files = e.target.files
    this.selectedFiles = [...files]
    this.validated = {}
    this.selectedFiles.map(file => this.validated[file.name] = null)
  }


  render() {
    return (
      <>
        <DownloadButton
          compact
          primary
          data-test-import-select
          icon='upload'
          labelPosition='left'
          content={t('radan.import.select')}
          onClick={() => this.fileInputRef.current.click()}
        />
        <input
          ref={this.fileInputRef}
          data-test-import-input
          type="file"
          hidden
          multiple
          onChange={this.filesChange}
        />
        <Divider/>
        <table>
          {this.selectedFiles.map(file => {
            const validationStatus = this.validated[file.name]
            return (
              <tr data-test-import-file={file.name}>
                <td>
                  <Popup
                    content={VALIDATED_TEXT[validationStatus]}
                    trigger={
                      <Icon 
                        name={VALIDATED_ICON[validationStatus]} 
                        color={VALIDATED_COLOR[validationStatus]}
                        data-test-import-validation-status={String(validationStatus)}
                      />
                    }
                  />
                </td>
                <td data-test-import-filename>{file.name}</td>
              </tr>
            )
          })}
        </table>
        <Divider/>
        {
          this.isAllValid()
          ?
          <DownloadButton
            compact
            primary
            data-test-import-upload
            icon="send"
            labelPosition="left"
            content={t('radan.import.upload')}
            onClick={this.uploadXml}
          />
          :
          <DownloadButton
            disabled={Object.values(this.validated).length === 0}
            compact
            primary
            data-test-import-validate
            icon="checkmark"
            labelPosition="left"
            content={t('radan.import.validate')}
            onClick={this.validateXml}
          />
        }
        {this.anyInvalid() && t('radan.import.someInvalid')}
        {this.validationError}
      </>
    )
  }

}


@observer
export default class RadanImportButton extends Component {
  static propTypes = {
    onOpenImportAction: PropTypes.object,
    afterUpload: PropTypes.func.isRequired,
    operator: PropTypes.instanceOf(Operator).isRequired,
  }


  @observable radanIntegration = null;
  @observable selectedFile = null;


  /**
   * Load the radan integration, we make the assumption there is only at most one for now
   */
  loadRadanIntegration = async () => {
    const store = new RadanIntegrationStore()
    await store.fetch()
    if (store.models.length) {
      this.radanIntegration = store.models[0]
    }
  }

  // Look for the integration
  componentDidMount() {
    this.loadRadanIntegration()
  }

  renderImportAction() {
    const { operator, afterUpload, onOpenImportAction } = this.props;

    if (!this.radanIntegration) {
      return (<></>)
    }
    return (
      <>
        <RadanImportForm
          radanIntegration={this.radanIntegration}
          operator={operator}
          afterUpload={() => {
            onOpenImportAction(null);
            if (afterUpload !== undefined) {
              afterUpload()
            }
          }}
        />
      </>
    )
  }


  render() {
    const { onOpenImportAction } = this.props;

    if (!this.radanIntegration) {
      return (
        <></>
      )
    }

    return (
      <SyncButton
        data-open-radan-import
        content="Radan import"
        onClick={() => {
          onOpenImportAction(this.renderImportAction())
        }}
      />
    )
  }
}
