import React from 'react';
import PropTypes from "prop-types";
import { observer } from 'mobx-react'
import { observable, runInAction, action } from 'mobx'
import { Batch } from "store/Batch";
import { Button, Modal, Icon } from 'semantic-ui-react'
import { Warehouse } from 'store/Warehouse';
import { Operator } from 'store/Operator'
import { StorageLocation } from 'store/StorageLocation';
import FormFields from './Form/FormFields';
import RightDivider from 'spider/component/RightDivider'
import { DetailMaterialTask, DetailMaterialTaskStore } from 'store/DetailMaterialTask'
import { DetailStore } from 'store/Detail'
import PerformStep from './Step'
import { Container } from './helpers'


@observer
export default class PerformFormStep extends PerformStep {
    static propTypes = {
        ...PerformStep.propTypes,
        batch: PropTypes.instanceOf(Batch).isRequired,
        batchSize: PropTypes.number.isRequired,
        warehouses: PropTypes.arrayOf(PropTypes.instanceOf(Warehouse)).isRequired,
        currentWarehouse: PropTypes.instanceOf(Warehouse),
        storageLocations: PropTypes.arrayOf(PropTypes.instanceOf(StorageLocation)).isRequired,
        predeterminedQuantity: PropTypes.number.isRequired,
        operator: PropTypes.instanceOf(Operator)
    }

    @observable allTasksDone = null
    @observable allMaterialsDone = null
    @observable showTaskCheckModal = null;
    @observable confirmedTasksMaterialCheck = null

    constructor(...args) {
        super(...args)

        this.setFormData = this.setFormData.bind(this)
        this.focus = this.focus.bind(this)
        this.onChangeMaterialTask = this.onChangeMaterialTask.bind(this)

        const { step, predeterminedQuantity, productionRequest } = this.props

        this.data = {}
        this.data.form_data = {}

        this.subBatchesDefault = {}

        // eslint-disable-next-line
        for (const field of step.formStep.form.fields.models) {
            if (field.type === 'quantity') {
                this.data.form_data[field.id] = predeterminedQuantity
            }

            if (field.type === 'sub_batches') {
                const subBatchesData = {}
                productionRequest.batches.filter(batch => batch.subProductionRequestBatchLinks.length > 0).forEach(batch => {
                    subBatchesData[batch.id.toString()] = batch.quantity
                })
                this.data.form_data[field.id] = subBatchesData
                this.subBatchesDefault = {...subBatchesData}
            }

            if (field.type === 'material_plan_material' || field.type === 'material_plan_task') {
                this.data.form_data[field.id] = {}
                productionRequest.productionOrder.materialPlan.items.forEach(item => {
                    if (!(this.data.form_data[field.id][item.articleType.id] || false) && item.backflush) {
                        this.data.form_data[field.id][item.articleType.id] = true
                    }
                });
            }
        }

    }

    componentDidMount() {
        super.componentDidMount()
        this.focus(0)
    }


    focus(index, subindex = 0) {
        const { step, productionRequest } = this.props

        const form = step.formStep.form
        const billOfMaterialVersion = productionRequest.productionOrder.billOfMaterialVersion

        for (let i = index; i < form.fields.length; i++) {
            const field = form.fields.at(i)
            if (field.type === 'sub_batches') {
                const fieldNode = this[`field_${i}_${subindex}`]
                if (fieldNode) {
                    fieldNode.inputElement.focus()
                    return
                } else {
                    continue
                }
            }

            const fieldData = field.type === 'bom'
                ? billOfMaterialVersion.items.models.slice(subindex).map((item, i) => [this.data.form_data[field.id] && this.data.form_data[field.id][item.articleType.id], subindex + i])
                : [[this.data.form_data[field.id], 0]]
            // eslint-disable-next-line
            for (const [value, j] of fieldData) {
                if (value === undefined) {
                    const fieldNode = this[`field_${i}${j !== 0 ? `_${j}` : ''}`]
                    if (!fieldNode) {
                        return
                    }
                    if (field.type === 'check') {
                        fieldNode.inputRef.current.focus()
                    } else if (['choice', 'warehouse'].includes(field.type)) {
                        fieldNode.handleFocus()
                    } else if (field.type === 'image') {
                        // Nothing to focus
                    } else if (field.type === 'quantity' || field.type === 'sub_batches') {
                        fieldNode.inputElement.focus()
                    } else {
                        fieldNode.focus()
                    }
                    return
                }
            }
        }
    }

    @action setFormData(key, value) {
        this.data.form_data[key] = value
        this.errors = this.errors.filter(({ path }) => !(path.length >= 2 && path[0] === 'form_data' && path[1] === key))
    }


    async onSubmit(quantity) {
        const { step, onPerform } = this.props

        this.errors = []
        try {
            return await onPerform({ ...this.data, quantity }, this.startedAt)
        } catch (err) {
            if (err.response && err.response.status === 400 && err.response.data.code === 'ValidationError') {
                const errors = err.response.data.errors
                runInAction(() => {
                    this.errors = errors
                    let formErrors = false
                    const form = step.formStep.form
                    // eslint-disable-next-line
                    for (const { path } of this.errors) {
                        if (path.length >= 2 && path[0] === 'form_data') {
                            const field = form.fields.get(parseInt(path[1]))
                            if (
                                field.type === 'measure' &&
                                this.data.form_data[path[1]] &&
                                path.length >= 3 &&
                                path[2] === 'value'
                            ) {
                                this.data.form_data[path[1]].value = null
                                this.data.form_data[path[1]].reason = null
                            } else if (
                                field.type === 'measure' &&
                                this.data.form_data[path[1]] &&
                                path.length >= 3 &&
                                path[2] === 'reason'
                            ) {
                                const { value } = this.data.form_data[path[1]]
                                if (value === null || (value >= field.measureMin && value <= field.measureMax)) {
                                    this.data.form_data[path[1]].reason = null
                                } else {
                                    this.data.form_data[path[1]].reason = ''
                                }
                            } else if (field.type === 'bom' && path.length >= 3) {
                                if (this.data.form_data[path[1]]) {
                                    delete this.data.form_data[path[1]][path[2]]
                                }
                            } else if (field.type === 'sub_batches' && path.length >= 3) {
                                this.data.form_data[path[1]][path[2]] = this.subBatchesDefault[path[2]]
                            } else {
                                delete this.data.form_data[path[1]]
                            }
                            formErrors = true
                        } else if (path.length >= 1 && path[0] === 'load_carrier') {
                            this.data.load_carrier = null
                            formErrors = true
                        } else if (path.length >= 1 && path[0] === 'serial_number') {
                            this.data.serial_number = ''
                            formErrors = true
                        }
                    }
                    if (formErrors) {
                        this.focus(0)
                    }
                })
            }
            throw err
        }
    }

    @action
    async onConfirm(print, toMain = false) {
        try {
            if ((this.allTasksDone !== null && !this.allTasksDone) || (this.allMaterialsDone !== null && !this.allMaterialsDone)) {
                this.confirmedTasksMaterialCheck = await new Promise((resolve, reject) => (this.showTaskCheckModal = { resolve, reject }))
            } else {
                this.confirmedTasksMaterialCheck = true
            }
        } finally {
            this.showTaskCheckModal = null
            this.allTasksDone = null
            this.allMaterialsDone = null
        }

        if (this.confirmedTasksMaterialCheck) {
            super.onConfirm(false, toMain)
        }
    }

    @action
    async onChangeMaterialTask(key, type, item, value, itemsFinished) {
        const checkedItem = (this.data.form_data[key] || {}).length > 0 && this.data.form_data[key][item.articleType.id] !== undefined && this.data.form_data[key][item.articleType.id]
        this.data.form_data[key] = { ...this.data.form_data[key] || {}, [item.articleType.id]: value }
        this.errors = this.errors.filter(({ path }) => !(path.length >= 3 && path[0] === 'form_data' && path[1] === key && path[2] === item.articleType.id.toString()))
        if (type === 'task') {
            this.allTasksDone = itemsFinished
        } else {
            this.allMaterialsDone = itemsFinished
        }

        // Do an update to the details of the performance
        if (this.confirmedTasksMaterialCheck !== null && !this.confirmedTasksMaterialCheck && !checkedItem && value) {
            const details = new DetailStore({ params: { '.field': key, order_by: '-performance.created_at' } })
            await details.fetch()
            const detailMaterialTasks = new DetailMaterialTaskStore({ params: { '.detail': details.at(0).id, '.bill_of_material_item': item.id } })
            await detailMaterialTasks.fetch()
            if (detailMaterialTasks.length === 1) {
                detailMaterialTasks.at(0).value = value
                await detailMaterialTasks.at(0).save()
            } else {
                const detailMaterialTask = new DetailMaterialTask({}, { relations: ['detail', 'billOfMaterialItem'] })
                detailMaterialTask.detail = details.at(0)
                detailMaterialTask.billOfMaterialItem = item
                detailMaterialTask.value = value
                await detailMaterialTask.save()
            }
        }
    }

    renderContent() {
        const { step, batch, batchSize, warehouses, storageLocations, currentWarehouse, productionRequest, stats, operator } = this.props

        return (
            <Container>
                <FormFields
                    productionRequest={productionRequest}
                    step={step}
                    batch={batch}
                    form={step.formStep.form}
                    batchSize={batchSize}
                    warehouses={warehouses}
                    currentWarehouse={currentWarehouse}
                    storageLocations={storageLocations}
                    quantityTodo={stats.quantityTodo}
                    errors={this.errors}
                    setErrors={(errors) => this.errors = errors}
                    data={this.data}
                    setFormData={this.setFormData}
                    onFocus={this.focus}
                    onAddRef={(key, ref) => this[key] = ref}
                    onChangeMaterialTask={this.onChangeMaterialTask}
                    operator={operator}
                />
                {this.showTaskCheckModal && (
                    <Modal open closeIcon data-test-unifined-tasks-material-modal size="small" onClose={() => this.showTaskCheckModal.reject()}>
                        <Modal.Header>
                            <Icon style={{ color: '#EBBB12', marginRight: '0.5em' }} name={'warning sign'} />
                            {t('workStation.production.performModal.tasksCheck.header')}
                        </Modal.Header>
                        <Modal.Content>{t('workStation.production.performModal.tasksCheck.content')}</Modal.Content>
                        <Modal.Actions>
                            <RightDivider />
                            <Button data-test-go-back-perform-modal
                                compact
                                icon="delete"
                                labelPosition="left"
                                content={t('workStation.production.performModal.tasksCheck.closeButton')}
                                onClick={() => this.showTaskCheckModal.resolve(false)}
                            />
                            <Button data-test-confirm-unifinished-exit
                                compact
                                icon="check"
                                labelPosition="left"
                                content={t('workStation.production.performModal.tasksCheck.confirmButton')}
                                onClick={() => this.showTaskCheckModal.resolve(true)}
                            />
                        </Modal.Actions>
                    </Modal>
                )}
            </Container>
        )
    }

}
