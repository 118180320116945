import {observer} from "mobx-react";
import React, {Component} from "react";
import {DetailStore} from "../../store/Detail";
import {Batch} from "../../store/Batch";
import {Icon, Image, Popup, Table} from "semantic-ui-react";
import styled from "styled-components";
import {targetDescription} from './TargetDescription'

const DetailsTable = styled.table`
    border-spacing: 0;

    &,
    tbody,
    tr,
    th,
    td {
        padding: 0;
        margin: 0;
    }

    th {
        text-align: left;
        padding-right: 0.5em;
        color: rgba(0, 0, 0, 0.5);
    }

    th,
    td {
        vertical-align: top;
    }
`

const DetailReason = styled.span`
    margin-left: 0.8em;
    font-size: 0.7em;
    font-weight: bold;
    color: #a08040;
    position: relative;
    bottom: 0.125em;

    > i.icon {
        margin-right: 0.4em !important;
    }
`

interface DetailInfoProps {
    details: DetailStore;
    batch: Batch;
    onTargetSelect: () => null
}

@observer
export default class DetailsInfo extends Component<DetailInfoProps> {
    constructor(args) {
        super(args)

        this.renderDetail = this.renderDetail.bind(this)
    }

    renderMaterialPlanField = (detail) => {
        const {batch} = this.props;

        return (
            <Table basic="very">

                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Performed</Table.HeaderCell>
                        <Table.HeaderCell>Amount required</Table.HeaderCell>
                        <Table.HeaderCell/>
                        <Table.HeaderCell>Description</Table.HeaderCell>

                    </Table.Row>
                </Table.Header>
                {detail.materials.map((detailMaterial) => (
                    <Table.Row>
                        <Table.Cell>{detailMaterial.value && (
                            <Icon name="check"/>
                        )}</Table.Cell>
                                                <Table.Cell>{detailMaterial.billOfMaterialItem.quantity * batch.quantity}</Table.Cell>
                        <Table.Cell>{detailMaterial.billOfMaterialItem.articleType.getLink()}</Table.Cell>
                        <Table.Cell>{detailMaterial.billOfMaterialItem.articleType.name}</Table.Cell>


                    </Table.Row>
                ))}
            </Table>
        )

    }

    renderDetail(detail) {
        const {onTargetSelect} = this.props

        const label = detail.field.label ?? detail.field.metafield.name

        let detailContent: JSX.Element | null;
        if (detail.field.type === 'check') {
            detailContent = <Icon name="check"/>
        } else if (['article_type', 'bom', 'assembly_bom'].includes(detail.field.type)) {
            detailContent = detail.components.map((batch) => (
                <React.Fragment key={batch.cid}>
                    {targetDescription(batch.batchUsings.at(0).usedBatch, onTargetSelect)}
                    <br />
                </React.Fragment>
            ))
        } else if (['material_plan_task', 'material_plan_material'].includes(detail.field.type)) {
            detailContent = this.renderMaterialPlanField(detail)
        } else if (detail.field.type === 'image') {
            detailContent = detail.images.map((detailImage) => (
                <Popup
                    hoverable
                    position="bottom center"
                    style={{left: '-12px'}}
                    key={detailImage.cid}
                    trigger={<Icon data-test-detail-image name="image outline"/>}
                    content={<Image src={detailImage.image}/>}
                />
            ))
        } else if (detail.field.type === 'storage_location') {
            detailContent = (
                <>
                    {`${detail.storageLocation.code}${detail.storageLocation.code !== '' && detail.storageLocation.name !== '' ? ' ' : ''}${detail.storageLocation.name} (${detail.storageLocation.warehouse.name})`}
                </>
            );
        } else if (detail.field.type === 'metafield') {
            if (detail.metavalue.file !== null) {
                detailContent = <img alt="" src={detail.metavalue.file}/>
            } else if (Array.isArray(detail.metavalue.value)) {
                detailContent = detail.metavalue.value.join(', ')
            } else {
                detailContent = detail.metavalue.value.toString()
            }
        } else {
            detailContent = detail.value
        }

        return (
            <tr key={detail.cid} data-test-target-detail={label}>
                <th>{label}</th>
                <td>
                    {detailContent}
                    {detail.reason !== null && (
                        <DetailReason>
                            <Icon name="warning sign"/>
                            {detail.reason}
                        </DetailReason>
                    )}
                </td>
            </tr>
        )
    }

    render() {
        const {details} = this.props

        return (
            <DetailsTable data-test-target-details-table>
                <tbody>{details.map(this.renderDetail)}</tbody>
            </DetailsTable>
        )
    }
}
