import { getPrinters, getPrinterType } from 'helpers/print'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { IconButton } from 'spider/semantic-ui/Button'
import { t } from '../../i18n'
import PrintersList from 'component/PrinterHelpers/PrintersList'

/**
   * Show the modal to select a printer. Either does not show a modal if print has not been seleceted,
   * shows a message that no printers are available or shows a list of printers to select from
   *
   * print should have form { printerType, printers, printer, ... }
   * 
   * @returns {JSX.Element}
   */
@observer
export default class PrinterSelector extends Component {
    static propTypes = {
        print: PropTypes.any.isRequired,
    }

    render() {
        const { print } = this.props

        if (print === null) {
            return <></>
        }
        if (print.printers.length === 0) {
            return <>
                {t('printModal.noPrintersAvailable')}
                <IconButton name="refresh" onClick={async () => {
                    print.printers = (await getPrinters()).filter((printer) => getPrinterType(printer) === this.print.printerType)
                }} />
            </>
        }
        return (
            <PrintersList data-test-printer-selector-list
                printers={print.printers}
                onSelect={(printer) => print.printer = printer}
            />
        )
    }
}
