import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { action, computed, observable, reaction } from 'mobx'
import { Icon, Form, Button, Table, Checkbox, Dropdown } from 'semantic-ui-react'
import ScrollModal from 'spider/semantic-ui/ScrollModal'
import { StyledTableRow, ItemButton } from 'spider/semantic-ui/Admin/Overview'
import RightDivider from 'spider/component/RightDivider'
import { TargetTextInput, TargetSelect, TargetNumberInput } from 'spider/semantic-ui/Target'
import { ProcessVersion } from 'store/ProcessVersion'
import Steps, { stepIcon } from 'component/Steps'
import sortSteps from 'helpers/sortSteps'
import styled from 'styled-components'
import { theme } from 'styles'
import Scrollbars from 'react-custom-scrollbars'
import AdminOverview, { EmptyMessageContainer } from 'component/AdminOverview'
import { ProductionLineMap } from 'container/ProductionLine/Edit'
import ProcessSelectModal from './SelectModal'
import { Step } from 'store/Step'
import { Part } from 'store/Section'
import { CapabilityStore } from 'store/Capability'
import { ProductionLineStore } from 'store/ProductionLine'
import { LabelDot } from 'spider/semantic-ui/'
import Sections from 'component/Sections'
import Tabs from 'component/Tabs'
import { BatchTypeStore } from 'store/BatchType'
import { omit } from 'lodash'

import PrintStepEdit from './Step/Print'
import FormStepEdit from './Step/Form'
import SplitStepEdit from './Step/Split'
import MultiplierStepEdit from './Step/Multiplier'
import SubprocessesStepEdit from './Step/Subprocesses'
import CarrierStepEdit from './Step/Carrier'
import ByproductStepEdit from './Step/ByProduct';
import NestStepEdit from './Step/Nest';
import ExportStepEdit from './Step/Export';

import WorkStationSelect from "component/WorkStationSelect";
export { NestStepEdit, ProcessSelectModal, PrintStepEdit, FormStepEdit, SplitStepEdit, MultiplierStepEdit, SubprocessesStepEdit, CarrierStepEdit, ByproductStepEdit, ExportStepEdit }



const STEP_EDIT = {
  print: PrintStepEdit,
  form: FormStepEdit,
  multiplier: MultiplierStepEdit,
  split: SplitStepEdit,
  carrier: CarrierStepEdit,
  byproduct: ByproductStepEdit,
  nest: NestStepEdit,
  export: ExportStepEdit,
}

const DISPLAY_TIME_UNITS = ['SECONDS', 'MINUTES', 'HOURS', 'DAYS']

const SmallForm = styled(Form)`
  max-width: 900px !important;
  margin: 0 auto;
`

const TableForm = styled(SmallForm)`
  padding: 25px;
  > .field > .ui.table {
    margin-top: 0 !important;
  }
`

const FlexActions = styled(ScrollModal.Actions)`
  display: flex;
`

const ButtonContainer = styled.div`
  margin-top: 1rem;
  text-align: center;
`

export const DraftBanner = styled.span`
  color: rgba(34, 36, 38, 0.45);
  font-size: 1.1rem;
  padding: 0.25rem 0.375rem;
  font-weight: bold;
  background-color: #f8f0d0;
  border-radius: 0.375rem;
  align-self: flex-start;
  margin-left: 0.5rem;
  position: relative;
  top: -0.1rem;
`

export const StepContainer = styled.div`
  display: flex;
  padding: 25px 0;
  text-align: center;
  flex: 0 0 auto;
  background-color: #e0e0e2;
  position: relative;
  overflow-y: hidden;
`

const InfoContainer = styled.div`
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0.1rem 0.3rem rgba(0, 0, 0, 0.025);
  background-color: #f9fafa;
`

const InfoInnerContainer = styled.div`
  padding: 25px 25px 6rem;
`

const InfoHeader = styled.div`
  text-align: center;
  margin: 1.5rem 0 0.5rem;
  font-size: 1.25rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
  &:first-child {
    margin-top: 0;
  }
`

const StepContent = styled.div`
  padding: 25px;
`

const ModeToggle = styled.div`
  display: flex;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  margin: 1rem 0;
  background-color: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 1.5rem;
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.1);
  padding: 0 0.5rem;
  z-index: 100;
`

const ModeToggleOption = styled.div`
  width: 2.5rem;
  height: 3rem;
  text-align: center;
  line-height: 3rem;
  cursor: pointer;
  > i.icon {
    font-size: 1.25rem;
    color: ${({ active }) => (active ? theme.primaryColor : 'rgba(0, 0, 0, 0.5)')};
  }
`
export function flattenSteps({ branches, steps }, target = []) {
  // eslint-disable-next-line
  for (const branch of branches) {
    flattenSteps(branch, target)
  }
  target.push(...steps)
  return target
}


class BatchTypes extends AdminOverview {
  constructor(...args) {
    super(...args)

    this.renderOverviewRow = this.renderOverviewRow.bind(this)
    this.onOpen = this.onOpen.bind(this)
    this.onClose = this.onClose.bind(this)
    this.addSelected = this.addSelected.bind(this)
  }

  @observable store = new BatchTypeStore({
    relations: this.props.batchTypes.__activeRelations.slice(),
  })
  @observable selected = new BatchTypeStore({
    relations: this.props.batchTypes.__activeRelations.slice(),
  })

  modal = true
  bindUrlParams = false
  fetchOnMount = false
  emptyMessage = t('articleType.overview.empty')

  @computed get settings() {
    return [
      {
        label: (
          <Checkbox
            checked={this.store.models.every(({ id }) => this.selected.get(id))}
            onChange={action((e, { checked }) => {
              this.selected.models = this.selected.filter(({ id }) => !this.store.get(id))
              if (checked) {
                // eslint-disable-next-line
                for (const batchType of this.store.models) {
                  this.selected.add(batchType.toJS())
                }
              }
            })}
          />
        ),
        attr: (batchType) => (
          <Checkbox
            checked={!!this.selected.get(batchType.id)}
            onChange={action((e, { checked }) => {
              this.selected.models = this.selected.filter(({ id }) => id !== batchType.id)
              if (checked) {
                this.selected.add(batchType.toJS())
              }
            })}
          />
        ),
        collapsing: true,
      },
      'name',
    ]
  }

  filters = [
    {
      label: t('articleType.field.name.label'),
      type: 'text',
      name: '.article_type.name:icontains',
    },
  ]

  rowProps(batchType, i) {
    const props = super.rowProps(batchType, i)
    if (this.selected.get(batchType.id)) {
      props.active = true
    }
    return props
  }

  @observable open = false

  @action onOpen() {
    this.open = true
    this.fetch()
  }

  @action onClose() {
    this.open = false
    this.selected.clear()
    this.store.clear()
  }

  @action addSelected() {
    const { batchTypes } = this.props
    // eslint-disable-next-line
    for (const batchType of this.selected.models) {
      batchTypes.add(batchType.toJS())
    }
    this.onClose()
  }

  renderOverviewRow(batchType, i) {
    const { batchTypes } = this.props

    return (
      <StyledTableRow key={batchType.cid}>
        {this.mappedSettings.slice(1).map((setting, i) => this.renderCell(batchType, setting, i))}
        <Table.Cell>
          <ItemButton
            icon="delete"
            label={t('tooltips.delete')}
            onClick={() => batchTypes.remove(batchType)}
            {...this.itemButtonProps}
          />
        </Table.Cell>
      </StyledTableRow>
    )
  }

  renderBody() {
    const { batchTypes } = this.props

    return (
      <TableForm>
        <Form.Field>
          <label>{t('processVersion.field.processVersionBatchTypes.label')}</label>
          {batchTypes.length === 0 ? (
            <EmptyMessageContainer>{t('processVersion.field.processVersionBatchTypes.none')}</EmptyMessageContainer>
          ) : (
            <Table>
              <Table.Header>
                <Table.Row>
                  {this.mappedSettings
                    .slice(1)
                    .map(({ sortKey, ...setting }) => setting)
                    .map(this.renderHeader.bind(this))}
                  <Table.HeaderCell collapsing />
                </Table.Row>
              </Table.Header>
              <Table.Body>{batchTypes.map(this.renderOverviewRow)}</Table.Body>
            </Table>
          )}
        </Form.Field>
        <ButtonContainer>
          <ScrollModal
            closeIcon
            open={this.open}
            onOpen={this.onOpen}
            onClose={this.onClose}
            size="fullscreen"
            trigger={<Button primary icon="add" labelPosition="left" content={t('form.addButton')} />}
          >
            <ScrollModal.Header>{t('process.edit.addBatchTypeModal.title')}</ScrollModal.Header>
            <ScrollModal.Content noPadding noScrollbars>
              {this.renderContent()}
            </ScrollModal.Content>
            <FlexActions>
              {this.renderPaginationControls()}
              <RightDivider />
              <Button
                primary
                icon="add"
                labelPosition="left"
                content={t('process.edit.addBatchTypeModal.addButton', { count: this.selected.length })}
                onClick={this.addSelected}
                disabled={this.selected.length === 0}
              />
            </FlexActions>
          </ScrollModal>
        </ButtonContainer>
      </TableForm>
    )
  }
}

@observer
export default class ProcessEdit extends Component {
  static propTypes = {
    process: PropTypes.object.isRequired,
    version: PropTypes.number,
    afterSave: PropTypes.func.isRequired,

    baseUrl: PropTypes.string.isRequired,
    view: PropTypes.string.isRequired,
    onViewChange: PropTypes.func.isRequired,
    machineEnabled: PropTypes.bool,
  }

  static defaultProps = {
    machineEnabled: false,
  }

  constructor(...args) {
    super(...args)
    this.save = this.save.bind(this)
    this.finalize = this.finalize.bind(this)
    this.nextVersion = this.nextVersion.bind(this)
    this.clearStepWorkStations = this.clearStepWorkStations.bind(this)
    this.selectStep = this.selectStep.bind(this)
    this.renderMapContent = this.renderMapContent.bind(this)
    this.renderMapStep = this.renderMapStep.bind(this)
    this.onCopy = this.onCopy.bind(this)
    this.renderStepInfo = this.renderStepInfo.bind(this)

    this.renderSteps = this.renderSteps.bind(this)
    this.renderInstructions = this.renderInstructions.bind(this)
    this.renderBatchTypes = this.renderBatchTypes.bind(this)
    this.renderMap = this.renderMap.bind(this)
  }

  @observable step = null
  @observable productionLineStore = new ProductionLineStore({
    relations: ['versions.workStations.locations'],
  })
  @observable capabilityStore = new CapabilityStore()

  componentDidMount() {
    this.clearSelectedStepReaction = reaction(
      () => this.props.version,
      () => (this.step = null)
    )
  }

  componentWillUnmount() {
    this.clearSelectedStepReaction()
  }

  selectStep(step) {
    if (this.step === step) {
      this.step = null
    } else {
      this.step = step
    }
  }

  @action save() {
    const { process, afterSave } = this.props
    if (process.isNew) {
      const versions = process.versions.models
      process.versions.models = []
      const promise = process.save({
        onlyChanges: true,
        params: { with: 'versions' },
        mapData: (data) => omit(data, 'versions'),
      })
      process.versions.models = versions

      return promise
        .then(
          action((res) => {
            this.version.id = res.data._meta.with.process_version[0].id
          })
        )
        .then(this.save)
    }

    return process
      .save({
        relations: [
          'versions.steps.sections.parts.textPart',
          'versions.steps.sections.parts.tablePart',
          'versions.steps.sections.parts.imagePart',
          'versions.steps.formStep.fields.scanConstraints',
          'versions.steps.splitStep.fields.scanConstraints',
          'versions.steps.printStep',
          'versions.steps.multiplierStep',
          'versions.steps.byproductStep',
          'versions.steps.nestStep',
          'versions.steps.carrierStep',
          'versions.steps.exportStep',
          'versions.steps.importStep',
        ],
        onlyChanges: true,
        mapData: (data) => omit(data, 'versions'),
      })
      .then((res) => {
        // eslint-disable-next-line
        for (const version of process.versions.models) {
          // eslint-disable-next-line
          for (const step of version.steps.models) {
            step.capabilities.clearSetChanges()
          }
        }
        return Promise.resolve(res)
      })
      .then(afterSave)
  }

  finalize() {
    const { process, afterSave } = this.props

    const step = this.step && this.step.id

    return this.version
      .finalize(process)
      .then(() => process.fetch())
      .then(() => (this.step = step && this.version.steps.get(step)))
      .then(afterSave)
  }

  nextVersion() {
    const { process, afterSave } = this.props

    let step = this.step && this.step.id

    return this.version
      .nextVersion()
      .then((res) => {
        step = step && res.meta.step_id_mapping[step]
        return process.fetch()
      })
      .then(() => (this.step = step && this.version.steps.get(step)))
      .then(afterSave)
  }

  @computed get version() {
    const { version, process } = this.props
    return (
      process.versions.models.find(version === null ? (v) => v.latest : (v) => v.version === version) ||
      new ProcessVersion(
        { version: 1, draft: true },
        { relations: process.__activeRelations.filter((r) => r.startsWith('versions.')).map((r) => r.substring(9)) }
      )
    )
  }

  @computed get lastVersion() {
    const { process } = this.props
    return (process.versions.at(process.versions.length - 1) || { version: 1 }).version
  }

  productionLineOption(productionLine) {
    return {
      value: productionLine.id,
      text: (
        <React.Fragment>
          <LabelDot color={productionLine.color} />
          {productionLine.name}
        </React.Fragment>
      ),
    }
  }

  @action clearStepWorkStations() {
    // eslint-disable-next-line
    for (const step of this.version.steps.models) {
      step.setInput('workStation', null)
    }
  }

  productionLineVersionOption(productionLineVersion) {
    return {
      value: productionLineVersion.id,
      text: t('productionLine.edit.version', { version: productionLineVersion.version }),
    }
  }

  @computed get productionLineVersionOptions() {
    const { process } = this.props
    return process.line.versions.map(this.productionLineVersionOption).reverse()
  }

  capabilityOption(capability) {
    return {
      value: capability.id,
      text: capability.name,
    }
  }

  renderStepForm(step) {
    const { machineEnabled } = this.props

    const StepDetails = STEP_EDIT[step.type]

    return (
      <SmallForm>
        {step.type !== 'multiplier' && (
          <React.Fragment>
            <TargetTextInput target={step} name="label" disabled={!this.version.draft} />
            <WorkStationSelect
              factory={this.version.factory}
              target={step}
              name="workStation"
              disabled={!this.version.draft}
              defaultProductionLineVersion={this.defaultProductionLineVersion}
            />
            <TargetSelect
              multiple
              remote
              target={step}
              name="capabilities"
              store={this.capabilityStore}
              toOption={this.capabilityOption}
              searchKey=".name:icontains"
              disabled={!this.version.draft}
            />
            <TargetNumberInput
              label={t('step.field.workMinutes.label')}
              target={step}
              name={!step.displayEstTime ? "workMinutes" : "displayEstTime"}
              onChange={(value) => {
                step.setInput('displayEstTime', value)
                step.setInput('workMinutes', step.convertEstTimeToMinutes)
              }}
              disabled={!this.version.draft}
              contentProps={{
                labelPosition: 'right',
                label: (
                  <Dropdown
                    value={!step.displayTimeUnit ? "minutes" : step.displayTimeUnit}
                    onChange={(e, { value }) => {
                      step.setInput('displayTimeUnit', value)
                      step.setInput('workMinutes', step.convertEstTimeToMinutes)
                    }}
                    options={DISPLAY_TIME_UNITS.map((value) => ({ value, text: value }))}
                    disabled={!this.version.draft}
                  />
                ),
              }}
            />
          </React.Fragment>
        )}
        <StepDetails step={step} steps={this.steps} disabled={!this.version.draft} machineEnabled={machineEnabled} />
      </SmallForm>
    )
  }

  @computed get steps() {
    return sortSteps(this.version.steps.models)
  }

  renderMapStep(step) {
    return (
      <div key={step.cid}>
        <Icon name={stepIcon(step)} />
        {step.label}
      </div>
    )
  }

  renderMapContent({ workStation }) {
    return this.version.steps.filter((s) => s.workStation.id === workStation.id).map(this.renderMapStep)
  }

  @action onCopy(version, process) {
    this.version.setInput('productionLineVersion', version.productionLineVersion)
    this.version.setInput('processVersion', version.processVersion)
    this.version.steps.clear()

    // Copy steps
    // eslint-disable-next-line
    for (const step of version.steps.models) {
      this.version.steps.add(step.toJS())
    }
    // Dedup nextSteps
    // eslint-disable-next-line
    for (const step of this.version.steps.models) {
      if (!step.nextStep.isNew) {
        step.nextStep = this.version.steps.get(step.nextStep.id)
      }
      step.markChanged('nextStep')
    }
    // Unset step ids
    // eslint-disable-next-line
    for (const step of this.version.steps.models) {
      step.id = null
      // eslint-disable-next-line
      for (const type of Step.TYPES) {
        step[`${type}Step`].id = null
        // eslint-disable-next-line
        for (const section of step.sections.models) {
          section.setInput('id', null)
          // eslint-disable-next-line
          for (const part of section.parts.models) {
            part.setInput('id', null)
            // eslint-disable-next-line
            for (const partType of Part.TYPES) {
              part[`${partType}Part`].setInput('id', null)
              if (partType === 'image') {
                if (part.imagePart.image !== null) {
                  fetch(part.imagePart.image)
                    .then((res) => res.blob())
                    .then((blob) => {
                      // So apparently Blob.preview is a react-dropzone only
                      // thing but mobx-spine expects it, so we fake it
                      blob.preview = URL.createObjectURL(blob)
                      part.imagePart.setInput('image', blob)
                    })
                }
              }
            }
          }
        }
        if (type === 'form') {
          // eslint-disable-next-line
          for (const field of step.formStep.fields.models) {
            field.setInput('id', null)
          }
        }
      }
    }
  }

  @computed get flatSteps() {
    return flattenSteps(this.steps)
  }

  @observable layoutEdit = false

  renderStepInfo(step, i) {
    return (
      <React.Fragment key={step.cid}>
        {step.type !== 'multiplier' && (
          <React.Fragment>
            <InfoHeader>
              <Icon name={stepIcon(step)} />
              {step.label}
            </InfoHeader>
            <Sections
              editable
              sections={step.sections}
              layoutEdit={this.layoutEdit}
              onMoveUp={
                i === 0
                  ? undefined
                  : action((section) => {
                    step.sections.remove(section)
                    this.flatSteps[i - 1].sections.models.push(section)
                    this.flatSteps[i - 1].sections.markChanged()
                  })
              }
              onMoveDown={
                i === this.flatSteps.length - 1
                  ? undefined
                  : action((section) => {
                    step.sections.remove(section)
                    this.flatSteps[i + 1].sections.models.splice(0, 0, section)
                    this.flatSteps[i + 1].sections.markChanged()
                  })
              }
              disabled={!this.version.draft}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }

  renderSteps() {
    return (
      <React.Fragment>
        <StepContainer>
          <Steps
            editable={this.version.draft}
            selected={this.step}
            onSelect={this.selectStep}
            steps={this.version.steps}
            color={theme.primaryColor}
          />
        </StepContainer>
        <StepContent>
          {this.step ? (
            this.renderStepForm(this.step)
          ) : (
            <EmptyMessageContainer>{t('process.edit.noStepSelected')}</EmptyMessageContainer>
          )}
        </StepContent>
      </React.Fragment>
    )
  }

  renderInstructions() {
    return (
      <InfoContainer>
        <Scrollbars>
          <InfoInnerContainer>{this.flatSteps.map(this.renderStepInfo)}</InfoInnerContainer>
        </Scrollbars>
        <ModeToggle>
          <ModeToggleOption active={!this.layoutEdit} onClick={() => (this.layoutEdit = false)}>
            <Icon name="pen" />
          </ModeToggleOption>
          <ModeToggleOption active={this.layoutEdit} onClick={() => (this.layoutEdit = true)}>
            <Icon name="th-large" />
          </ModeToggleOption>
        </ModeToggle>
      </InfoContainer>
    )
  }

  renderBatchTypes() {
    return <BatchTypes batchTypes={this.version.batchTypes} />
  }

  renderMap() {
    return (
      <div style={{ display: 'flex', height: '100%' }}>
        <ProductionLineMap
          productionLineVersion={this.version.productionLineVersion}
          renderContent={this.renderMapContent}
        />
      </div>
    )
  }

  render() {
    const { process, baseUrl } = this.props
    return (
      <React.Fragment>
        <Tabs
          maxWidth="100%"
          header={process.id ? t('process.edit.title') : t('process.create.title')}
          afterHeader={this.version.draft && <DraftBanner>{t('process.edit.draft')}</DraftBanner>}
          baseUrl={`${baseUrl}/`}
          defaultTab="steps"
          tabs={[
            {
              key: 'steps',
              label: t('process.edit.tabs.steps'),
              icon: 'ellipsis horizontal',
              render: this.renderSteps,
              noPadding: true,
              bgColor: '#E0E0E2',
            },
            {
              key: 'instructions',
              label: t('process.edit.tabs.instructions'),
              icon: 'info',
              render: this.renderInstructions,
              noPadding: true,
              bgColor: '#F9FAFA',
            },
            {
              key: 'batch-types',
              label: t('process.edit.tabs.batchTypes'),
              icon: 'boxes',
              render: this.renderBatchTypes,
              noPadding: true,
            },
            {
              key: 'map',
              label: t('process.edit.tabs.map'),
              icon: 'map',
              render: this.renderMap,
              noPadding: true,
              bgColor: '#E0E0E2',
            },
          ]}
        />
      </React.Fragment>
    )
  }
}
