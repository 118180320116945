import React, {Component} from "react";
import {Moment} from "moment";

interface DifferenceProps {
    planned: Moment;
    actual: Moment;
}

/**
 * Display the difference in time between two moments
 */
export class Difference extends Component<DifferenceProps>{

    get diff(): string {
        const {planned, actual} = this.props

        return `${actual.diff(planned, 'hours')} hours`;
    }
    render() {

        return (
            <>
                {this.diff}
            </>
        )
    }
}
