import { Content } from 're-cy-cle'
import styled from 'styled-components'

const SmallContent = styled(Content)`
  main {
    max-width: 900px;
  }
`

export default SmallContent
