import styled from "styled-components";
import {Icon, Label} from "semantic-ui-react";

export const StyledLabel = styled(Label)`
  cursor: pointer;
  white-space: nowrap;
`

export const StyledIcon = styled(Icon)`
  vertical-align: bottom;
`
