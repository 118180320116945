import {TargetSelectOptions} from "./types";
import {t} from "i18n";
import DefaultPerformView from 'screen/Progress/Views/Default';
import ExportPerformView from 'screen/Progress/Views/Export';
import {Component} from "react";
import {WorkStation} from "../store/WorkStation";

export const VIEW_DEFAULT = 'default';
export const VIEW_EXPORT = 'export';

export const VIEWS: string[] = [VIEW_DEFAULT, VIEW_EXPORT];

/**
 * For each type, register the Perform view that needs to be loadad
 */
export const VIEW_MAPPING =  new Map<string, typeof Component>([
    [VIEW_DEFAULT, DefaultPerformView],
    [VIEW_EXPORT, ExportPerformView],
])

export const VIEW_OPTIONS: TargetSelectOptions = VIEWS.map(view => ({
    value: view,
    text: t(`workStation.views.${view}`)
}));


/**
 * For a workstation, determine the type of the element which needs to be rendered
 *
 * @param workStation
 */
export function getWorkstationView(workStation: WorkStation | null): typeof Component {

    if (!workStation) {
        return DefaultPerformView;
    }

    let workStationView =  VIEW_MAPPING.get(workStation.view);

    if (!workStationView) {
        workStationView = DefaultPerformView;
    }

    return workStationView;
}

