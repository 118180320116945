import { Model } from 'store/Base';
import { isFeatureFlagEnabled } from '../helpers/featureFlags';

// Retrieves the desired carrier integration from a main integration
export function getCarrierIntegration(integration){
    if (integration.janKredietIntegration.id != null) {
        return integration.janKredietIntegration
    }
    else if (integration.transmissionIntegration.id != null) {
        return integration.transmissionIntegration
    }
    else if (integration.upsIntegration.id != null) {
        return integration.upsIntegration
    }

    return null
}

// Returns a list of the active carrier integrations 
export function getActiveCarrierIntegrations(){
    const activeCarrierIntegrations : string[] = []
    if(isFeatureFlagEnabled('carrier_integration')){
        activeCarrierIntegrations.push("transmission");
    }
    if(isFeatureFlagEnabled('jan_krediet_integration')){
        activeCarrierIntegrations.push("jan_krediet");
    }
    if(isFeatureFlagEnabled('ups_integration')){
        activeCarrierIntegrations.push('ups');
    }

    return activeCarrierIntegrations
}

export abstract class CarrierIntegration extends Model {
    
}