import React from 'react';
import { observable, runInAction, action } from 'mobx'
import PropTypes from "prop-types";
import { observer } from 'mobx-react'
import { api } from 'store/Base'
import { Button, Form, Label, Icon, Modal } from 'semantic-ui-react'
import { Batch } from "store/Batch";
import { Warehouse } from 'store/Warehouse';
import { StorageLocation } from 'store/StorageLocation';
import { showSaveNotification, showNotification } from 'helpers/notification'
import { TargetTextInput, ErrorLabel, TargetSelect, TargetNumberInput } from 'spider/semantic-ui/Target'
import { print, getPrinters, getPrinterType } from 'helpers/print'
import { CustomTargetMultiTextInput } from 'component/TargetMultiPut'
import FormFields from './Form/FormFields';
import styled from 'styled-components'
import RightDivider from 'spider/component/RightDivider'
import { DetailMaterialTask, DetailMaterialTaskStore } from 'store/DetailMaterialTask'
import { DetailStore } from 'store/Detail'
import PerformStep from './Step'
import { StyledToolbar, Container, LAST_USED_KEY } from './helpers'
import { humanReadable } from 'helpers/decimal';


const SmallTargetNumberInput = styled(TargetNumberInput)`
  > .ui.input > input {
    max-width: 100px;
  }
  margin-left: 0.75em;
`


@observer
export default class PerformSplitStep extends PerformStep {
    static propTypes = {
        ...PerformStep.propTypes,
        batch: PropTypes.instanceOf(Batch).isRequired,
        batchSize: PropTypes.number.isRequired,
        warehouses: PropTypes.arrayOf(PropTypes.instanceOf(Warehouse)).isRequired,
        currentWarehouse: PropTypes.instanceOf(Warehouse),
        storageLocations: PropTypes.arrayOf(PropTypes.instanceOf(StorageLocation)).isRequired,
        predeterminedQuantity: PropTypes.number.isRequired,
    }


    @observable printData = null
    @observable printed = false
    @observable printing = false
    @observable selectedPrinter = null
    @observable availablePrinters = null
    @observable copies = 1
    @observable serialNumbers = []
    @observable serialNumberError = ''
    @observable allTasksDone = null
    @observable allMaterialsDone = null
    @observable showTaskCheckModal = null;
    @observable confirmedTasksMaterialCheck = null


    constructor(...args) {
        super(...args)

        this.onPrint = this.onPrint.bind(this)
        this.onPrintAll = this.onPrintAll.bind(this)
        this.searchPrinters = this.searchPrinters.bind(this)
        this.setFormData = this.setFormData.bind(this)
        this.findStockSerialNumber = this.findStockSerialNumber.bind(this)
        this.focus = this.focus.bind(this)
        this.onChangeMaterialTask = this.onChangeMaterialTask.bind(this)

        const { step, predeterminedQuantity, productionRequest } = this.props

        this.data.form_data = {}

        // eslint-disable-next-line
        for (const field of step.splitStep.form.fields.models) {
            if (field.type === 'quantity') {
                this.setFormData(field.id, predeterminedQuantity)
            }
            if (field.type === 'material_plan_material' || field.type === 'material_plan_task') {
                this.data.form_data[field.id] = {}
                productionRequest.productionOrder.materialPlan.items.forEach(item => {
                    if (!(this.data.form_data[field.id][item.articleType.id] || false) && item.backflush) {
                        this.data.form_data[field.id][item.articleType.id] = true
                    }
                });
            }
        }

        if (step.splitStep.type === 'scan') {
            this.data.load_carrier = null
        }
        if (step.splitStep.type === 'provided') {
            this.data.serial_number = ''
        }
    }


    componentDidMount() {
        super.componentDidMount()
        const { step } = this.props

        this.focus(0)

        if (step.splitStep.type === 'print') {
            this.searchPrinters()
            this.copies = step.splitStep.copies
        }
    }

    async searchPrinters() {
        const { step } = this.props

        this.availablePrinters = null
        let printerType = step.splitStep.printer
        let defaultPrinter = step.splitStep.defaultPrinter

        const availablePrinters = (await getPrinters()).filter((printer) => getPrinterType(printer) === printerType)

        runInAction(() => {
            this.availablePrinters = availablePrinters

            const lastUsedPrinter = localStorage.getItem(LAST_USED_KEY)
            if (availablePrinters.length === 1) {
                this.selectedPrinter = availablePrinters[0]
            } else if (availablePrinters.includes(defaultPrinter)) {
                this.selectedPrinter = defaultPrinter
            } else if (availablePrinters.includes(lastUsedPrinter)) {
                this.selectedPrinter = lastUsedPrinter
            }
        })
    }

    async findStockSerialNumber(serialNumber) {
        const { productionRequest } = this.props
        const articleType = productionRequest.processVersion.batchType.articleType

        try {
            await api.get('stock_serial_number/check_exact/', { 'serial_number': serialNumber, 'article_type': articleType.id }, { supressError: true })
            runInAction(() => {
                this.serialNumberError += t('workStation.production.performModal.splitStep.alreadySyncedToExact', { serialNumber: serialNumber }) + ', '
            })
            return false
        } catch (error) {
            if (error.response.status === 418) {
                runInAction(() => {
                    this.serialNumberError += t('workStation.production.performModal.splitStep.integrationNotActive') + ", "
                })
                return false
            }
            return true
        }
    }


    focus(index, subindex = 0) {
        const { step, productionRequest } = this.props


        const form = step.splitStep.form
        const billOfMaterialVersion = productionRequest.productionOrder.billOfMaterialVersion

        for (let i = index; i < form.fields.length; i++) {
            const field = form.fields.at(i)
            // eslint-disable-next-line
            for (const [value, j] of (
                field.type === 'bom'
                    ? billOfMaterialVersion.items.models.slice(subindex).map((item, i) => [this.data.form_data[field.id] && this.data.form_data[field.id][item.articleType.id], subindex + i])
                    : [[this.data.form_data[field.id], 0]]
            )) {
                if (value === undefined) {
                    const fieldNode = this[`field_${i}${j !== 0 ? `_${j}` : ''}`]
                    if (!fieldNode) {
                        return
                    }
                    if (field.type === 'check') {
                        fieldNode.inputRef.current.focus()
                    } else if (['choice', 'warehouse'].includes(field.type)) {
                        fieldNode.handleFocus()
                    } else if (field.type === 'image') {
                        // Nothing to focus
                    } else if (field.type === 'quantity') {
                        fieldNode.inputElement.focus()
                    } else {
                        fieldNode.focus()
                    }
                    return
                }
            }
        }

        if (this.loadCarrierNode && this.data.load_carrier === null) {
            this.loadCarrierNode.focus()
        }

        if (this.serialNumberNode && this.data.serial_number === '') {
            this.serialNumberNode.focus()
        }
    }


    async onPrint(quantity = 1, skipPrintOnPaper = false) {
        const { step } = this.props

        this.printing = true
        try {
            // Get print data to a uniform format.
            // Split step is a label print thus we create an object that has fields
            // similar to a label print on a printStep
            let printSettings = {
                copies: step.splitStep.copies,
                type: 'label',
                labelPrinter: step.splitStep.printer,
                labelTemplate: step.splitStep.template,
            }
            // Actual print
            const performPrint = async (printData, i) => {
                const _copies = this.copies !== printSettings.copies ? this.copies : printSettings.copies
                await print(this.selectedPrinter, printData.instructions, { copies: _copies, key: i })
            }

            const printPromises = [];
            if (this.printData === null) {
                const allPrintData = [];
                try {
                    for (let i = 0; i < quantity; i++) {
                        const printData = await this.onSubmit();
                        allPrintData.push(printData);
                        if (!skipPrintOnPaper) {
                            printPromises.push(performPrint(printData, i));
                        }
                    }
                } catch (error) {
                    if (allPrintData.length === 0) {
                        throw error;
                    }
                }
                this.printData = allPrintData;
            } else if (!skipPrintOnPaper) {
                printPromises.push(...this.printData.map(performPrint));
            }

            await Promise.all(printPromises);

            this.printed = true
            showNotification(skipPrintOnPaper
                ? t('workStation.production.performModal.printStep.printSkipped')
                : t('workStation.production.performModal.printStep.printedSuccesfully')
            )
        } catch (err) {
            window.viewStore.showNotification({
                key: step.id,
                error: true,
                dismissAfter: 4000,
                message: `${err}`,
                icon: 'print',
            })
        } finally {
            this.printing = false
        }
    }

    async onPrintAll() {
        const { maxPerformCount } = this.props
        return this.onPrint(maxPerformCount)
    }

    @action setFormData(key, value) {
        this.data.form_data[key] = value
        this.errors = this.errors.filter(({ path }) => !(path.length >= 2 && path[0] === 'form_data' && path[1] === key))
    }

    renderExtraFields() {
        const { step, productionRequest, maxPerformCount } = this.props

        return (
            <>
                {step.splitStep.type === 'scan' && (
                    <tr>
                        <td>{t('batch.field.loadCarrier.label')}</td>
                        <td />
                        <td>
                            <TargetTextInput fluid noLabel data-test-production-perform-load-carrier
                                // for some bullshit reason this value does not change if it is null instead of the empty string
                                value={this.data.load_carrier || ''}
                                onChange={(loadCarrier) => this.data['load_carrier'] = loadCarrier}
                                errors={this.errors
                                    .filter(({ path }) => path.length >= 1 && path[0] === 'load_carrier')
                                    .map(({ message }) => message)}
                                contentProps={{ ref: (ref) => (this.loadCarrierNode = ref) }}
                            />
                        </td>
                    </tr>
                )}
                {step.splitStep.type === 'provided' && (
                    <tr>
                        <td>{t('batch.field.serialNumber.label')}</td>
                        <td>{step.splitStep.form.fields.length === 0 && t('workStation.production.performModal.splitStep.bulkEntrySerialNumbersDetail')}</td>
                        <td>
                            {step.splitStep.form.fields.length === 0 ? (
                                <>
                                    <Form.Group widths="equal">
                                        <CustomTargetMultiTextInput
                                            fluid noLabel
                                            data-test-production-perform-bulk-serial-number
                                            value={this.serialNumbers}
                                            onChange={async (value) => {
                                                runInAction(() => {
                                                    this.serialNumberError = ''
                                                })
                                                if (value.length > maxPerformCount) {
                                                    runInAction(() => {
                                                        this.serialNumberError = t('workStation.production.performModal.splitStep.expectedQuantity');
                                                    })
                                                } else {
                                                    const serialNumber = value[value.length - 1]
                                                    if (serialNumber !== undefined) { // Adding new entry
                                                        if (!step.newBatchSerialNumberRegex.test(serialNumber)) {
                                                            runInAction(() => {
                                                                this.serialNumberError += t('workStation.production.performModal.splitStep.wrongFormat', { serialNumber: serialNumber }) + ', ';
                                                            })
                                                        } else {
                                                            var checkExact = true
                                                            // When receiving serial articles, check if serial number has already been added
                                                            if (productionRequest.processVersion.batchType.type === 'buy' && productionRequest.processVersion.batchType.articleType.isSerial) {
                                                                checkExact = await this.findStockSerialNumber(serialNumber)
                                                            }
                                                            if (checkExact) {
                                                                runInAction(() => {
                                                                    this.serialNumbers = value
                                                                    this.serialNumberError = ''
                                                                })
                                                            }
                                                        }
                                                    } else { // Removing existing entry
                                                        runInAction(() => {
                                                            this.serialNumbers = value
                                                            this.serialNumberError = ''
                                                        })
                                                    }
                                                }
                                            }}
                                            errors={this.errors
                                                .filter(({ path }) => path.length >= 1 && path[0] === 'serial_number')
                                                .map(({ message }) => message)}
                                        />
                                        {maxPerformCount - this.serialNumbers.length > 0 ? (
                                            <Label size='big' data-test-remaining-perform-count>{maxPerformCount - this.serialNumbers.length}</Label>
                                        ) : (
                                            <Icon name="check" color='green' size='big' />
                                        )}
                                    </Form.Group>
                                    {this.serialNumberError !== '' && (
                                        <ErrorLabel data-test-serial-number-error>
                                            {this.serialNumberError}
                                        </ErrorLabel>
                                    )}
                                </>
                            ) : (
                                <TargetTextInput fluid noLabel data-test-production-perform-serial-umber
                                    // for some bullshit reason this value does not change if it is null instead of the empty string
                                    value={this.data.serial_number || ''}
                                    onChange={async (serialNumber) => {
                                        runInAction(() => {
                                            this.serialNumberError = ''
                                        })
                                        if (serialNumber !== undefined && !step.newBatchSerialNumberRegex.test(serialNumber)) {
                                            runInAction(() => {
                                                this.serialNumberError += t('workStation.production.performModal.splitStep.wrongFormat', { serialNumber: serialNumber }) + ', ';
                                            })
                                        } else {
                                            var checkExact = true
                                            // When receiving serial articles, check if serial number has already been added
                                            if (productionRequest.processVersion.batchType.type === 'buy' && productionRequest.processVersion.batchType.articleType.isSerial) {
                                                checkExact = await this.findStockSerialNumber(serialNumber)
                                            }
                                            if (checkExact) {
                                                runInAction(() => {
                                                    this.data['serial_number'] = serialNumber
                                                    this.serialNumberError = ''
                                                })
                                            }
                                        }
                                    }}
                                    errors={this.errors
                                        .filter(({ path }) => path.length >= 1 && path[0] === 'serial_number')
                                        .map(({ message }) => message)}
                                    contentProps={{ ref: (ref) => (this.serialNumberNode = ref) }}
                                />
                            )}
                        </td>
                    </tr>
                )}
            </>
        )
    }

    async onSubmit(quantity) {
        const { step, onPerform } = this.props

        this.errors = []
        try {
            return await onPerform({ ...this.data, quantity }, this.startedAt)
        } catch (err) {
            if (err.response && err.response.status === 400 && err.response.data.code === 'ValidationError') {
                const errors = err.response.data.errors
                runInAction(() => {
                    this.errors = errors
                    let formErrors = false
                    // eslint-disable-next-line
                    for (const { path } of this.errors) {
                        if (path.length >= 2 && path[0] === 'form_data') {
                            const field = step.splitStep.form.fields.get(parseInt(path[1]))
                            if (
                                field.type === 'measure' &&
                                this.data.form_data[path[1]] &&
                                path.length >= 3 &&
                                path[2] === 'value'
                            ) {
                                this.data.form_data[path[1]].value = null
                                this.data.form_data[path[1]].reason = null
                            } else if (
                                field.type === 'measure' &&
                                this.data.form_data[path[1]] &&
                                path.length >= 3 &&
                                path[2] === 'reason'
                            ) {
                                const { value } = this.data.form_data[path[1]]
                                if (value === null || (value >= field.measureMin && value <= field.measureMax)) {
                                    this.data.form_data[path[1]].reason = null
                                } else {
                                    this.data.form_data[path[1]].reason = ''
                                }
                            } else if (field.type === 'bom' && path.length >= 3) {
                                if (this.data.form_data[path[1]]) {
                                    delete this.data.form_data[path[1]][path[2]]
                                }
                            } else {
                                delete this.data.form_data[path[1]]
                            }
                            formErrors = true
                        } else if (path.length >= 1 && path[0] === 'load_carrier') {
                            this.data.load_carrier = null
                            formErrors = true
                        } else if (path.length >= 1 && path[0] === 'serial_number') {
                            this.data.serial_number = ''
                            formErrors = true
                        }
                    }
                    if (formErrors) {
                        this.focus(0)
                    }
                })
            }
            throw err
        }
    }

    @action
    onSubmitAndClose(goToMain = false) {
        const { step, onClose } = this.props

        if (step.splitStep.form.fields.length === 0 && this.serialNumbers.length > 0) {
            runInAction(() => this.serialNumberError = '')
            return this.serialNumbers.forEach(async (sn) => {
                this.data.serial_number = sn
                try {
                    const res = await this.onSubmit()
                    if (res) {
                        runInAction(() => this.serialNumbers.remove(sn))
                        showNotification({
                            key: `${sn}-success`,
                            dismissAfter: 2000,
                            message: sn,
                        })
                        showSaveNotification()
                        if (this.serialNumbers.length === 0) {
                            return onClose()
                        }
                    }
                } catch (err) {
                    const snErrors = this.errors
                        .filter(({ path }) => path.length >= 1 && path[0] === "serial_number")
                        .map(({ message }) => message).join('\n');
                    runInAction(() => this.serialNumberError += sn + ': ' + snErrors + ', ')
                    return;
                }
            })
        }

        return super.onSubmitAndClose(goToMain)
    }

    @action
    onSplitConfirm = async (print, toMain = false) => {
        try {
            if ((this.allTasksDone !== null && !this.allTasksDone) || (this.allMaterialsDone !== null && !this.allMaterialsDone)) {
                this.confirmedTasksMaterialCheck = await new Promise((resolve, reject) => (this.showTaskCheckModal = { resolve, reject }))
            } else {
                this.confirmedTasksMaterialCheck = true
            }
        } finally {
            this.showTaskCheckModal = null
            this.allTasksDone = null
            this.allMaterialsDone = null
        }

        console.log(this.confirmedTasksMaterialCheck)
        if (this.confirmedTasksMaterialCheck) {
            this.onConfirm(print, toMain)
        }
    }

    renderButtons() {
        const { step, productionRequest, maxPerformCount } = this.props

        const splitPrint = step.splitStep.type === 'print'
        const confirmDisabled = splitPrint && !this.printed;
        const showGoToMain = ['buy', 'sell', 'transfer_line'].includes(productionRequest.processVersion.batchType.type)
        const showGoToNextLinesButton = step.nextStep.type === 'subprocesses' && ['pick_order', 'receive_order', 'transfer'].includes(productionRequest.processVersion.batchType.type)

        let printersOptions
        if (this.availablePrinters === null) {
            printersOptions = []
        } else if (this.availablePrinters.length === 0) {
            printersOptions = [{ value: null, text: <i>{t('workStation.production.performModal.printStep.noPrintersAvailable')}</i> }]
        } else {
            printersOptions = this.availablePrinters.map((printer) => ({ value: printer, text: printer }))
        }

        return (
            <StyledToolbar>
                {/* Stats are not shown at the moment until we figure out a good place 
                    where to put them if the screen is too small. */}
                {/* {this.renderStats()} */}
                <RightDivider />
                {splitPrint && (
                    <>
                        <TargetSelect noLabel data-test-printers-selector
                            value={this.selectedPrinter}
                            placeholder={t('workStation.production.performModal.printStep.selectPrinter')}
                            options={printersOptions}
                            onChange={(printer) => this.selectedPrinter = printer}
                            loading={this.availablePrinters === null}
                        />
                        <label style={{ alignSelf: 'center', marginLeft: '0.75em' }}>{t('printModal.copies')}</label>
                        <SmallTargetNumberInput data-test-number-of-copies
                            value={this.copies === null ? '' : this.copies.toString()}
                            onChange={(value) => this.copies = value === '' ? null : parseInt(value)}
                        />
                        {this.printData === null ? (
                            <>
                                <Button primary data-test-print-button
                                    icon="print"
                                    labelPosition="left"
                                    content={t('workStation.production.performModal.splitStep.printButton')}
                                    loading={this.printing}
                                    disabled={this.selectedPrinter === null || this.copies === null}
                                    onClick={() => this.onPrint()}
                                />
                                <Button primary data-test-print-skip-button
                                    icon="print"
                                    labelPosition="left"
                                    content={t('workStation.production.performModal.splitStep.skipPrintButton')}
                                    loading={this.printing}
                                    disabled={this.selectedPrinter === null || this.copies === null}
                                    onClick={() => this.onPrint(1, true)}
                                />
                                {step.splitStep.form.fields.length === 0 && (
                                    <Button primary data-test-print-all-button
                                        icon="print"
                                        labelPosition="left"
                                        content={t('workStation.production.performModal.splitStep.printAllButton', { count: maxPerformCount })}
                                        disabled={this.printData !== null || this.selectedPrinter === null}
                                        loading={this.printing}
                                        onClick={() => this.onPrintAll()}
                                    />
                                )}
                            </>
                        ) : (
                            <Button primary data-test-reprint-button
                                icon="print"
                                labelPosition="left"
                                content={t('workStation.production.performModal.splitStep.reprintButton', { count: this.printData.length })}
                                loading={this.printing}
                                onClick={() => this.onPrint()}
                            />
                        )}
                    </>
                )}
                <Button primary data-test-confirm-button
                    icon="check"
                    labelPosition="left"
                    content={t('workStation.production.performModal.confirmButton', {count: humanReadable(maxPerformCount)})}
                    onClick={() => this.onSplitConfirm(splitPrint, false)}
                    disabled={confirmDisabled}
                />
                {(showGoToNextLinesButton || showGoToMain) && (
                    <Button primary data-test-go-to-main-production-request
                        icon="arrow right"
                        labelPosition="left"
                        content={t('workStation.production.performModal.goToMain')}
                        onClick={() => this.onSplitConfirm(splitPrint, true)}
                        disabled={confirmDisabled}
                    />
                )}
            </StyledToolbar>
        )
    }

    @action
    async onChangeMaterialTask(key, type, item, value, itemsFinished) {
        const checkedItem = (this.data.form_data[key] || {}).length > 0 && this.data.form_data[key][item.articleType.id] !== undefined && this.data.form_data[key][item.articleType.id]
        this.data.form_data[key] = { ...this.data.form_data[key] || {}, [item.articleType.id]: value }
        this.errors = this.errors.filter(({ path }) => !(path.length >= 3 && path[0] === 'form_data' && path[1] === key && path[2] === item.articleType.id.toString()))
        if (type === 'task') {
            this.allTasksDone = itemsFinished
        } else {
            this.allMaterialsDone = itemsFinished
        }

        // Do an update to the details of the performance
        if (this.confirmedTasksMaterialCheck !== null && !this.confirmedTasksMaterialCheck && !checkedItem && value) {
            const details = new DetailStore({ params: { '.field': key, order_by: '-performance.created_at' } })
            await details.fetch()
            const detailMaterialTasks = new DetailMaterialTaskStore({ params: { '.detail': details.at(0).id, '.bill_of_material_item': item.id } })
            await detailMaterialTasks.fetch()
            if (detailMaterialTasks.length === 1) {
                detailMaterialTasks.at(0).value = value
                await detailMaterialTasks.at(0).save()
            } else {
                const detailMaterialTask = new DetailMaterialTask({}, { relations: ['detail', 'billOfMaterialItem'] })
                detailMaterialTask.detail = details.at(0)
                detailMaterialTask.billOfMaterialItem = item
                detailMaterialTask.value = value
                await detailMaterialTask.save()
            }
        }
    }

    renderContent() {
        const { step, batch, batchSize, warehouses, storageLocations, currentWarehouse, productionRequest, stats } = this.props

        return (
            <Container>
                <FormFields
                    productionRequest={productionRequest}
                    step={step}
                    batch={batch}
                    form={step.splitStep.form}
                    batchSize={batchSize}
                    warehouses={warehouses}
                    currentWarehouse={currentWarehouse}
                    storageLocations={storageLocations}
                    quantityTodo={stats.quantityTodo}
                    errors={this.errors}
                    setErrors={(errors) => this.errors = errors}
                    data={this.data}
                    setFormData={this.setFormData}
                    disabled={this.printed}
                    extraFields={this.renderExtraFields()}
                    onFocus={this.focus}
                    onAddRef={(key, ref) => this[key] = ref}
                    onChangeMaterialTask={this.onChangeMaterialTask}
                />
                {this.showTaskCheckModal && (
                    <Modal open closeIcon data-test-unifined-tasks-material-modal size="small" onClose={() => this.showTaskCheckModal.reject()}>
                        <Modal.Header>
                            <Icon style={{ color: '#EBBB12', marginRight: '0.5em' }} name={'warning sign'} />
                            {t('workStation.production.performModal.tasksCheck.header')}
                        </Modal.Header>
                        <Modal.Content>{t('workStation.production.performModal.tasksCheck.content')}</Modal.Content>
                        <Modal.Actions>
                            <RightDivider />
                            <Button data-test-go-back-perform-modal
                                compact
                                icon="delete"
                                labelPosition="left"
                                content={t('workStation.production.performModal.tasksCheck.closeButton')}
                                onClick={() => this.showTaskCheckModal.resolve(false)}
                            />
                            <Button data-test-confirm-unifinished-exit
                                compact
                                icon="check"
                                labelPosition="left"
                                content={t('workStation.production.performModal.tasksCheck.confirmButton')}
                                onClick={() => this.showTaskCheckModal.resolve(true)}
                            />
                        </Modal.Actions>
                    </Modal>
                )}
            </Container>
        )
    }

}
