import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Image, Modal } from 'semantic-ui-react'
import { ProductionRequest } from 'store/ProductionRequest'


export default class ProductionRequestDrawingImage extends Component {
  static propTypes = {
    productionRequest: PropTypes.instanceOf(ProductionRequest).isRequired,
    size: PropTypes.string,
  }

  static defaultProps = {
    size: 'tiny'
  }


  render() {
    const { productionRequest, size } = this.props

    if (!productionRequest) {
      return null
    }

    if (!productionRequest.drawingImage) {
      return null
    }

    return (
      <Modal
        closeIcon
        trigger={
          <Image
            data-test-sub-batch-bitmap={productionRequest.id}
            data-test-image-imported={Boolean(productionRequest.drawingImage)}
            size={size}
            src={productionRequest.drawingImage}
            style={{cursor:'pointer'}}
          />
        }
      >
        <Modal.Content image>
          <Image
            data-test-modal-image
            data-test-image-imported={Boolean(productionRequest.drawingImage)}
            size='big'
            src={productionRequest.drawingImage}
          />
        </Modal.Content>
      </Modal>
    )
  }
}