import React, {Component} from "react";
import {ProductionRequest} from "../../store/ProductionRequest";
import {formatPeriod} from "../../screen/Planner/helpers";
import styled from "styled-components";

interface PlannedWeekTagProps {
    productionRequest: ProductionRequest;
}

const StyledLabel = styled.div`
  background-color: #EEEEEE;
  width: 48px;
  height: 48px;
  overflow-wrap: break-word;
  text-align: center;
`;

export default class PlannedWeekTag extends Component<PlannedWeekTagProps> {

    get period(): string | null {
        const {productionRequest} = this.props;
        if (productionRequest.period !== null) {
            return productionRequest.period
        }
        // @ts-ignore
        const superRequest: ProductionRequest = productionRequest.superrequest;

        if (superRequest) {
            return superRequest?.period
        }

        return null;
    }


    render() {
        if (!this.period) {
            return (<></>)
        }

        const period = formatPeriod(this.period)

        if (!period) {
            return (<></>)
        }

        const [line1, line2] = period.split(' ')

        return (
            <StyledLabel data-test-planned-at>
                {line1}< br/>
                {line2}
            </StyledLabel>
        )
    }
}
