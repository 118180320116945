import React, {Component} from "react";
import {Grid} from 'semantic-ui-react';
import {ProductionRequest} from 'store/ProductionRequest';
import PlannedWeekTag from "./PlannedWeekTag";
import styled from "styled-components";
import {formatDuration, SHORT_DATETIME_FORMAT} from "../../helpers";
import {Moment} from "moment";
import {t} from "../../i18n";
import {Caption1} from "../PerformanceStyles";

interface PlannedOnInformationProps {
    productionRequest: ProductionRequest;
}

const PlannedOnInformationContainer = styled.div`
    width: 300px;
`;


export default class PlannedOnInformation extends Component<PlannedOnInformationProps> {
    render() {
        const {productionRequest} = this.props;

        // @ts-ignore
        const actualStartedAt: Moment | null = productionRequest.startedAt;
        const plannedStartedAt: Moment | null = productionRequest.startAt;


        const erpPlannedEndDate = productionRequest.erpPlannedEndDate;

        return (
            <PlannedOnInformationContainer>
                <Grid columns={4}>
                    <Grid.Column>
                        <PlannedWeekTag
                            productionRequest={productionRequest}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Caption1>Start </Caption1>
                        <Caption1 data-test-start-at>
                            {
                                // @ts-ignore
                                plannedStartedAt?.format(SHORT_DATETIME_FORMAT) || '-'
                            }
                        </Caption1>
                        <Caption1>
                            {   // @ts-ignore
                                actualStartedAt?.format(SHORT_DATETIME_FORMAT) || t('productionRequest.notStartedYet')
                            }
                        </Caption1>

                    </Grid.Column>
                    <Grid.Column>
                        <Caption1>{t('productionRequest.erpEnd')}</Caption1>
                        <Caption1>
                            {   // @ts-ignore
                                erpPlannedEndDate?.format(SHORT_DATETIME_FORMAT) || '-'
                            }
                        </Caption1>
                        <br/>
                    </Grid.Column>
                    <Grid.Column>
                        <Caption1>Duration:&nbsp;&nbsp;</Caption1>
                        <Caption1> <span
                            data-test-total-process-time>{formatDuration(productionRequest.totalProcessTime, {unit: 'minute'})}</span></Caption1>
                    </Grid.Column>
                </Grid>
            </PlannedOnInformationContainer>

        );
    }
}
