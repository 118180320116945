import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { Integration } from './Integration'

export class ExactGlobeIntegration extends Model {
  static backendResourceName = 'exact_globe_integration'
  static omitFields = ['active']

  @observable id = null
  @observable username = ''
  @observable password = null
  @observable baseUrl = null
  @observable serverName = ''
  @observable databaseName = ''

  @observable itemTopic = true
  @observable accountTopic = true
  @observable salesOrderTopic = true
  @observable purchaseOrderTopic = true
  @observable warehouseTopic = true

  active = true

  relations() {
    return {
      integration: Integration,
    }
  }
}

export class ExactGlobeIntegrationStore extends Store {
  static backendResourceName = 'exact_globe_integration'
  Model = ExactGlobeIntegration
}
