import { LoginModal } from 'spider/semantic-ui/Login'
import { WorkspaceLoginModal } from 'component/WorkspaceLoginModal'
import { BrowserRouter } from 'react-router-dom'
import show, { clear } from 'helpers/modal'
import { configureFeatureFlags } from 'helpers/featureFlags'
import { setOnPrintError } from 'helpers/print'
import { Operator } from 'store/Operator'
import { get } from 'lodash'
import { getPrinters, print, printPdf } from 'helpers/print'

import 'daycy/dist/daycy.css'

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import App from './container/App';
import ViewStore from 'spider/store/View';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { theme } from './styles.js';
import { t } from './i18n';
import i18next from 'i18next';
// use router instead of browserRouter for sentry, see
// https://github.com/getsentry/sentry-javascript/issues/3107#issuecomment-741431381
import { ReCyCleTheme } from 're-cy-cle';
import configureSentry, {setUserSentry} from './sentry';
import { PUBLIC_URL, configOverride } from 'helpers';
import { configureModal } from 'helpers/modal';
import { configureNotification } from 'helpers/notification';
import { configurePermission } from 'spider/component/Permission';
import { configureCurrentUser } from 'helpers/currentUser';
import { CancelButton, ApplyButton } from 'spider/semantic-ui/Button';
import { configureBasename, configureAfterSave } from 'spider/helpers/bindUrlParams';
import { Modal } from 'semantic-ui-react';
import RightDivider from 'spider/component/RightDivider';
import 'moment-duration-format';
import {api, subscribe} from 'store/Base';
import { User } from 'store/User';
import { configureTranslation } from 'daycy';
import {createBrowserHistory} from 'history';

import 'daycy/dist/daycy.css';
import 'style/semantic-ui/foo/bar/main.css';
import 'style/semantic-ui/daycy.css';
import 'style/semantic-ui.css';
import 'style/extra-icons.css';
import 'style/custom-icons/cy-custom-icons.css';

window.t = t

configureTranslation((key, args) => {
  return t(`daycy.${key}`, args)
})

class SpecialViewStore extends ViewStore {
  @observable isWorkStation = false
  @observable workStationCode = null
  @observable workStationName = null
  @observable isOperator = false
  @observable exactEnabled = true
  @observable machineEnabled = false
  @observable tabTitlePrefix = 'Tracy'
  @observable featureFlags = [];
  @observable progressScope = 'week'
  @observable altecPrinterNames = []
  @observable zebraPrinterNames = []
  @observable tracyTagSize = 'normal'

  @observable currentOperator = new Operator()

  /**
   * Dictionary of workstations which are administrative methods
   *
   * MApping of workstation_id => workstation name
   */
  @observable administrativeWorkstations = {}

  constructor(...args) {
    super(...args)

    this.clockIn = this.clockIn.bind(this)
    this.clockOut = this.clockOut.bind(this)
    this.handlePrintJob = this.handlePrintJob.bind(this)
    this.handleWebhookUpdates = this.handleWebhookUpdates.bind(this)

    setOnPrintError((err) =>
      this.showNotification({
        key: 'printError',
        error: true,
        dismissAfter: 4000,
        message: err.toString(),
        icon: 'exclamation triangle',
      })
    )
  }

  fetchBootstrap() {
    this.bootstrapCode = null

    const { with: user_with } = this.currentUser.api.buildFetchModelParams(this.currentUser)
    const { with: operator_with } = this.currentOperator.api.buildFetchModelParams(this.currentOperator)

    return this.api
      .get('/bootstrap/', { user_with, operator_with })
      .then(this.handleBootstrap.bind(this))
      .catch((err) => {
        this.bootstrapCode = get(err, 'response.status', 500)
        throw err
      })
  }

      parseCurrentUserFromBootstrap(res) {
        this.currentUser.fromBackend({
          data: res.user.data,
          repos: res.user.with,
          relMapping: res.user.with_mapping,
        });
        setUserSentry(this.currentUser)
      }

  @action handleBootstrap(res) {
    configOverride(res)

    if (this.printJobSubscription) {
      this.printJobSubscription.unsubscribe()
    }

    if (this.webhooksSubscription) {
      this.webhooksSubscription.unsubscribe()
    }

    this.isWorkStation = res.is_work_station
    this.workStationCode = res.work_station_code
    this.workStationName = res.work_station_name
    this.isOperator = res.is_operator
    this.exactEnabled = res.exact_enabled
    this.machineEnabled = res.machine_enabled
    this.tabTitlePrefix = res.title_prefix
    this.featureFlags = res.feature_flags
    this.progressScope = res.progress_scope
    this.altecPrinterNames = res.altec_printer_names
    this.zebraPrinterNames = res.zebra_printer_names
    this.tracyTagSize = res.tracy_tag_size
    this.administrativeWorkstations = res.administrative_work_stations;
    res = super.handleBootstrap(res)

    if (res.operator) {
      this.parseCurrentOperatorFromBootstrap(res)
    } else {
      this.currentOperator.clear()
    }

    if (this.isWorkStation || this.isOperator) {
      this.setupSocket()
    }

    if (this.isWorkStation) {
      this.syncPrinters()
      this.printJobSubscription = subscribe(
        {
          type: 'print_job_status_change',
          id: '*',
          work_station_session: res.work_station_session,
          status: 'todo',
        },
        this.handlePrintJob,
      )
    }

    if (this.isAuthenticated) {
      this.webhooksSubscription = subscribe(
        {
          type: 'exact_webhooks_update',
          integration: '*',
        },
        this.handleWebhookUpdates,
      )
    }

    return res
  }

  async syncPrinters() {
    return await this.api.post('work_station_session/printers/', {
      printers: (
        this.isWorkStation
          ? await getPrinters().catch(() => [])
          : []
      )
    })
  }

  async handlePrintJob({ data: { id, printer, content, copies } }) {
    try {
      if (
        window.viewStore.altecPrinterNames.includes(printer) ||
        window.viewStore.zebraPrinterNames.includes(printer)
      ) {
        await print(printer, content, { copies })
      } else {
        await printPdf(printer, content, { copies })
      }
    } catch (e) {
      return await this.api.put(`/print_job/${id}/`, { status: 'failed' })
    }
    return await this.api.put(`/print_job/${id}/`, { status: 'done' })
  }

  handleWebhookUpdates({ data: { topic, counts } }) {
    this.showNotification({
      key: topic,
      message: `Webhook update for topic ${topic}!`,
      dismissAfter: 5000,
    })
    // eslint-disable-next-line
    for (const [topic, topicCounts] of Object.entries(counts)) {
      // eslint-disable-next-line
      for (const [system, systemCounts] of Object.entries(topicCounts)) {
        // eslint-disable-next-line
        for (const [action, count] of Object.entries(systemCounts)) {
          const notificationKey = topic + action + count
          this.showNotification({
            key: notificationKey,
            message: t(`exactIntegration.overview.syncResult.success.count.${topic}.${system}.${action}`, { count }),
            dismissAfter: 5000,
          })
        }
      }
    }
  }


  parseCurrentOperatorFromBootstrap(res) {
    this.currentOperator.fromBackend({
      data: res.operator.data,
      repos: res.operator.with,
      relMapping: res.operator.with_mapping,
    })
  }

  async clockIn() {
    const data = await this.api.post('/work_time/clock_in/')
    const employee = !this.currentOperator.isNew ? this.currentOperator : this.currentUser
    employee.parse(data)
    this.showSaveNotification()
  }

  async clockOut() {
    const data = await this.api.post('/work_time/clock_out/')
    const employee = !this.currentOperator.isNew ? this.currentOperator : this.currentUser
    employee.parse(data)
    this.showSaveNotification()
  }
}

const viewStore = new SpecialViewStore({

  api,
  user: new User(null, {
    relations: ['groups.permissions', 'articleTypePermissionScopes.articleType', 'operator'],
  }),
  socketUrl: `${PUBLIC_URL || ''}/ws/`,
})
window.viewStore = viewStore


/**
 * Currently test newAppVersionNotification abuses the fact that viewStore is
 * globally available. We should only expose this when debug = true. BOEK has
 * a debug mode, where you can see more in the interface (like calculations)
 * and have access to scary buttons.
 */
window.viewStore = viewStore;

export const history = createBrowserHistory();
configureSentry(viewStore, history);

configureModal(viewStore);
configureNotification(viewStore);
configurePermission(viewStore);
configureBasename(PUBLIC_URL);
configureAfterSave({ goBack: false, createUrl: '/add' });
configureCurrentUser(viewStore);
configureFeatureFlags(viewStore)

@observer
class Root extends Component {
  @observable showAlert = false
  @observable alertMessage = ''
  @observable alertConfirm = null

  // Custom alert callbacks.
  @observable alertOnApply = null
  @observable alertOnCancel = null


    componentDidMount() {
        i18next.on('languageChanged', () => this.forceUpdate());
    }

    componentWillUnmount() {
        i18next.off('languageChanged');
    }



  cancel = () => {
    this.showAlert = false

    if (this.alertOnCancel) {
      this.alertOnCancel()
    }

    this.alertConfirm(false)
    this.alertOnApply = null
    this.alertOnCancel = null
  }

  confirm = () => {
    this.showAlert = false

    if (this.alertOnApply) {
      this.alertOnApply()
    }

    this.alertConfirm(true)
    this.alertOnApply = null
    this.alertOnCancel = null
  }



  render() {
    return (
      <React.Fragment>
        <Modal size="tiny" open={this.showAlert} centered={false}>
          <Modal.Content style={{ textAlign: 'center' }}>
            <p>{this.alertMessage}</p>
            <p>{t('form.confirmQuestion')}</p>
          </Modal.Content>
          <Modal.Actions style={{ display: 'flex' }}>
            <CancelButton negative onClick={this.cancel} />
            <RightDivider />
            <ApplyButton positive onClick={this.confirm} content={t('form.continueButton')} />
          </Modal.Actions>
        </Modal>
        <ReCyCleTheme theme={theme}>
          <BrowserRouter
            basename={PUBLIC_URL}
            getUserConfirmation={(message, confirm, ...args) => {
              this.showAlert = true
              this.alertConfirm = confirm

              if (typeof message === 'object') {
                this.alertMessage = message.message
                this.alertOnApply = message.onApply
                this.alertOnCancel = message.onCancel
              } else {
                this.alertMessage = message
                this.alertOnApply = null
                this.alertOnCancel = null
              }
            }}
          >
            <App store={viewStore} />
          </BrowserRouter>
        </ReCyCleTheme>
      </React.Fragment>
    )
  }
}

let notAuthenticatedRequests = []
let isLoginModalShown = false

api.axios.interceptors.response.use(null, (err) => {
  const status = get(err, 'response.status')
  const statusErrCode = get(err, 'response.data.code')

  if (status === 403 && statusErrCode === 'NotAuthenticated' && err.response.config.url !== '/api/user/login/') {
    if (!isLoginModalShown) {
      isLoginModalShown = true
      if (viewStore.isWorkStation) {
        show(WorkspaceLoginModal, {
          viewStore,
          afterLogin: () => {
            clear()
            isLoginModalShown = false
            notAuthenticatedRequests.forEach((pending) => {
              if (pending.err.response.config.headers['X-Csrftoken']) {
                pending.err.response.config.headers['X-Csrftoken'] = api.csrfToken
              }

              api
                .axios({
                  ...pending.err.response.config,
                  baseURL: '/api/',
                })
                .then(pending.resolve)
                .catch(pending.reject)
            })

            notAuthenticatedRequests = []
          },
        })
      } else if (viewStore.isOperator) {
        isLoginModalShown = false
      } else {
        show(LoginModal, {
          viewStore,
          usernameField: 'email',
          afterLogin: () => {
            clear()
            isLoginModalShown = false
            notAuthenticatedRequests.forEach((pending) => {
              if (pending.err.response.config.headers['X-Csrftoken']) {
                pending.err.response.config.headers['X-Csrftoken'] = api.csrfToken
              }

              api
                .axios({
                  ...pending.err.response.config,
                  baseURL: '/api/',
                })
                .then(pending.resolve)
                .catch(pending.reject)
            })

            notAuthenticatedRequests = []
          },
        })
      }
    }

    return new Promise((resolve, reject) => notAuthenticatedRequests.push({ resolve, reject, err }))
  }

  return Promise.reject(err)
})

ReactDOM.render(<Root />, document.getElementById('root'))
