import { observable, computed } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import moment from 'moment'
import { ExactIntegration } from 'store/ExactIntegration'
import { ExactItem } from 'store/ExactItem'
import { ExactShopOrder } from 'store/ExactShopOrder'
import { ExactSalesOrder } from './SalesOrder'

export class ExactDocument extends Model {
  static backendResourceName = 'exact_document'

  @observable id = null
  @observable uuid = null
  @observable type = null
  @observable typeDescription = null
  @observable documentSubject = null;
  @observable documentViewUrl = null
  @observable documentDate = null


  casts() {
    return {
      created: Casts.datetime,
      modified: Casts.datetime,
    }
  }

  relations() {
    return {
      integration: ExactIntegration,
      exactItem: ExactItem,
      exactShopOrder: ExactShopOrder,
      exactSalesorder: ExactSalesOrder
    }
  }

  @computed get status() {
    if (this.performedAt) {
      return 'done';
    }
    if (this.failedAt) {
      return 'failed';
    }
    return 'open';
  }

  async retry() {
    this.failedAt = null;
    this.performedAt = null;
    return this.save()
  }

  async finish() {
    this.failedAt = null;
    this.performedAt = moment();
    return this.save()
  }
}

export class ExactDocumentStore extends Store {
  static backendResourceName = 'exact_document'
  Model = ExactDocument
}
