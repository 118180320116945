import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { action, computed } from 'mobx'
import { observer } from 'mobx-react'
import { Step, SplitStep } from 'store/Step'
import { t } from 'i18n'
import { FormEdit } from './Form'
import { LABEL_PRINTER_OPTIONS as PRINTER_OPTIONS, getAllowedKeys } from './Print'
import { TargetNumberInput, TargetRadioButtons, TargetSelect } from 'spider/semantic-ui/Target'
import { IconButton } from 'spider/semantic-ui/Button'
import TargetTemplate from 'component/TargetTemplate'
import TargetIcon from 'component/TargetIcon'
import PrintButtonModal from 'component/PrintButtonModal'
import styled from 'styled-components'
import { PROCESS_TYPES, TEMPLATE_TYPES } from 'store/BatchType'
import { MetafieldsContext } from 'component/Metafields'
import { BatchType } from '../../../store/BatchType'

const TYPE_OPTIONS = SplitStep.TYPES.map((type) => ({
  value: type,
  text: t(`splitStep.field.type.value.${type}`),
  tooltip: t(`splitStep.field.type.tooltip.${type}`),
}))

const PrintButton = styled(IconButton)`
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
  &:hover {
    color: rgba(0, 0, 0, 0.5);
  }
  margin: 0 !important;
`

@observer
export default class SplitStepEdit extends Component {
  static propTypes = {
    step: PropTypes.instanceOf(Step).isRequired,
    steps: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    machineEnabled: PropTypes.bool,
    batchEnabled: PropTypes.bool,
    batchType: PropTypes.instanceOf(BatchType).isRequired,
    variable: PropTypes.shape({
      before: PropTypes.bool.isRequired,
      after: PropTypes.bool.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    disabled: false,
    machineEnabled: false,
    batchEnabled: true, // TODO should be false but we do not have the feature flag yet
  }

  static contextType = MetafieldsContext

  @computed get allowedKeys() {
    const { step, steps, batchType } = this.props
    return getAllowedKeys(step, steps, this.context, {
      erpSlugs: PROCESS_TYPES.includes(batchType.type) || TEMPLATE_TYPES.includes(batchType.type),
    })
  }

  render() {
    const { step, disabled, machineEnabled, batchType, variable } = this.props

    let printerOptions = []
    if (step.splitStep.printer === 'altec') {
      printerOptions = window.viewStore.altecPrinterNames
    } else if (step.splitStep.printer === 'zebra') {
      printerOptions = window.viewStore.zebraPrinterNames
    }

    return (
      <React.Fragment>
        <TargetIcon target={step.splitStep} name="icon" disabled={disabled} />
        <FormEdit
          label={t('splitStep.field.form.label')}
          batchType={batchType}
          form={step.splitStep.form}
          disabled={disabled}
          machineEnabled={machineEnabled}
          allowedKeys={this.allowedKeys}
          isTemplate={batchType.type.endsWith('_template')}
          variable={{ ...variable, before: step.splitStep.newBatchVariableQuantity }}
        />
        <TargetRadioButtons
          target={step.splitStep}
          name="type"
          disabled={disabled}
          options={TYPE_OPTIONS}
          onChange={action((type) => {
            step.splitStep.setInput('type', type)
            if (type === 'print') {
              if (step.splitStep.printer === null) {
                step.splitStep.setInput('printer', 'altec')
              }
              if (step.splitStep.template === '') {
                step.splitStep.setInput('template', '{{serial_number}}')
              }
            }
            step.splitStep.setInput('defaultPrinter', null)
          })}
        />
        {step.splitStep.type === 'print' && (
          <React.Fragment>
            <TargetRadioButtons
              target={step.splitStep}
              name="printer"
              disabled={disabled}
              options={PRINTER_OPTIONS}
              onChange={action((printer) => {
                step.splitStep.setInput('printer', printer)
                step.splitStep.setInput('defaultPrinter', null)
              })}
            />
            <TargetSelect clearable
              target={step.splitStep}
              name="defaultPrinter"
              disabled={disabled || step.splitStep.printer === null}
              options={printerOptions.map((printer) => ({ value: printer, text: printer }))}
            />
            <TargetNumberInput target={step.splitStep} name="copies" disabled={disabled} />
            <TargetTemplate
              target={step.splitStep}
              name="template"
              disabled={disabled}
              allowedKeys={this.allowedKeys}
              viewTo={
                <PrintButtonModal
                  title={t('splitStep.field.template.label')}
                  getInstructions={() => step.splitStep.template}
                  trigger={<PrintButton name="print" data-test-print-template-button />}
                />
              }
            />
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}
