import React, {Component} from "react";
import {BulkActionsButton} from "spider/semantic-ui/Button";
import {observer} from "mobx-react";
import {ProductionRequest, ProductionRequestStore} from "../../store/ProductionRequest";
import {Step} from "../../store/Step";
import {Batch} from "../../store/Batch";
import {t} from "i18n";
import {getBatchesForStep, sortProductionRequestsForWorkStation} from "../../helpers/productionRequest";
import {WorkStation} from "../../store/WorkStation";
import PROCESSS_STEPS, {isMultiStep} from "../../feature/processStep";
import { Operator } from "store/Operator";

// Relations needed for production request for the multi action
export const MULTI_ACTION_RELATIONS = [
    'processVersion.steps.workStation',
    'processVersion.steps.processVersion',
    'processVersion.steps.exportStep.integration',
    'processVersion.steps.importStep.integration',
    'processVersion.steps.nextStep.workStation',
    'batches.lastStep.nextStep.workStation',
];

interface PerformMultiActionProps {
    selectedProductionRequests: ProductionRequestStore;
    workStation: WorkStation;

    /**
     * Callback if action is selected
      * @param action
     */
    onSelectMultiAction: (action: JSX.Element | null) => void;

    operator: Operator;

    afterPerform: () => void;
}

interface BulkActionInterface {
    key: string,
    text: string;
    action: () => void;

    ['data-test-action-step']: number|string;
    ['data-test-disabled']: boolean;

    disabled?: boolean;


}


@observer
export default class PerformMultiAction extends Component<PerformMultiActionProps>{


    get actions() {
        const {workStation, selectedProductionRequests, onSelectMultiAction, operator, afterPerform} = this.props;
        const allSteps  = sortProductionRequestsForWorkStation(workStation, selectedProductionRequests)
        const steps = new Map<Step, ProductionRequest[]>()
        const exportSteps = new Map<Step, ProductionRequest[]>()
        allSteps.forEach((productionRequests: ProductionRequest[], step: Step) => {
            // @ts-ignore
            if (step.exportStep.isNew) {
                steps.set(step, productionRequests)
            } else {
                exportSteps.set(step, productionRequests)
            }
        })
        const actions: BulkActionInterface[] = []

        // Handle regular steps
        // @ts-ignore
        for ( const [step, productionRequests] of steps) {
            const count = productionRequests.length;
            // @ts-ignore
            const isDisabled= !isMultiStep(step.type);
            actions.push({
                // @ts-ignore
                key: step.id,
                text: `${step.label} (${count})`,
                action: () => {
                    // @ts-ignore
                    const multiStepViewFactory = PROCESSS_STEPS.get(step.type)?.multiStepView;
                    if (!multiStepViewFactory) {
                        return;
                    }
                    const batches = getBatchesForStep(step, productionRequests);

                    const MultiStepView = multiStepViewFactory();
                    const multistepElement = (
                        <MultiStepView
                            step={step}
                            batches={batches}
                            operator={operator}
                            afterPerform={() => {
                                onSelectMultiAction(null);
                                afterPerform();
                            }}
                        />
                    );

                    onSelectMultiAction(
                      multistepElement
                    )
                },
                // @ts-ignore
                'data-test-action-step': step.id,
                disabled: isDisabled,
                'data-test-disabled': isDisabled,
            })
        }

        // Handle export steps
        // This is done by creating a single action for all the export steps together, and each step will be performed individually later on
        if (exportSteps.size > 0) {
            const stepIds = []
            const type = exportSteps.keys().next().value.type
            const batches: Batch[] = []
            let count = 0
            // @ts-ignore
            for (const [step, productionRequests] of exportSteps) {
                // @ts-ignore
                stepIds.push(step.id)
                batches.push(...getBatchesForStep(step, productionRequests))
                count += productionRequests.length
            }
            const actionKey = stepIds.join(',')
            const isDisabled = false
            actions.push({
                key: actionKey,
                text: `${t('radan.export')} (${count})`,
                action: () => {
                    const multiStepViewFactory = PROCESSS_STEPS.get(type)?.multiStepView;
                    if (!multiStepViewFactory) {
                        return;
                    }

                    const MultiStepView = multiStepViewFactory();
                    const multistepElement = (
                        <MultiStepView
                            steps={exportSteps}
                            batches={batches}
                            operator={operator}
                            afterPerform={() => {
                                onSelectMultiAction(null);
                                afterPerform();
                            }}
                        />
                    );

                    onSelectMultiAction(
                      multistepElement
                    )
                },
                'data-test-action-step': actionKey,
                disabled: isDisabled,
                'data-test-disabled': isDisabled,
            })
        }

        return actions;
    }



    render() {
        const {selectedProductionRequests} = this.props;

        return (
            <BulkActionsButton
                store={selectedProductionRequests}
                actions={this.actions}
                data-test-select-bulk-action
                text={t('progress.bulkActionButton.choose', { count: `${selectedProductionRequests.length}` })}
            />
        )
    }

}
