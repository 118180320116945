import React from 'react';
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import { TYPE_INTEGRATION_RADAN } from "../../../store/Integration";
import RadanImportStep from "./Import/Radan";
import { Batch } from "store/Batch";
import { Operator } from 'store/Operator';
import PerformStep from './Step'
import { Container } from './helpers'



@observer
export default class PerformImportStep extends PerformStep {

  static propTypes = {
    operator: PropTypes.instanceOf(Operator).isRequired,
    ...PerformStep.propTypes,
    batch: PropTypes.instanceOf(Batch).isRequired
  }

  /**
   * Get the content depending on the type of integration
   */
  getIntegrationContent = () => {
    const { step, operator } = this.props;
    const integration = step.importStep?.integration;

    if (!integration) {
      return (<></>)
    }

    if (integration.type === TYPE_INTEGRATION_RADAN) {
      return (
        <RadanImportStep
          integration={integration}
          operator={operator}
        />
      )
    }

    return (<></>);
  }

  render() {
    const content = this.getIntegrationContent();
    return (
      <>
        <Container>
          {content}
        </Container>
        {this.renderButtons()}
      </>
    )
  }

}
