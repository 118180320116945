import { observable } from 'mobx'
import { each, get } from 'lodash'
import { getMoneyInputProps } from 'spider/helpers'
import { t } from 'i18n'
import { setMockPrinter } from 'helpers/print'
import { DATE_FORMAT } from 'helpers/date'
import moment from 'moment'
import { Model } from 'mobx-spine'
import {formatDuration} from "./helpers/duration";
export {formatDuration};

export let TAB_TITLE_PREFIX = process.env.REACT_APP_NAME + ' - ';
export let FRONTEND_API_BASE_URL = process.env.REACT_APP_CY_FRONTEND_API_BASE_URL || '/api/';

// Config set by bootstrap.
export let MAPS_API_KEY = ''
export let MAPS_API_URL = ''
export const BUILD_INFO = observable({
  version: 'dev',
})

export function configOverride(bootstrap) {
  MAPS_API_KEY = bootstrap.google_maps_api_key
  MAPS_API_URL = bootstrap.google_maps_api_url
  Object.assign(BUILD_INFO, bootstrap.build_info)

  //setMockPrinter(!!bootstrap.config_override.MOCK_PRINTER) We're getting this from the Global settins now..
  setMockPrinter(!!bootstrap.mock_printer)
}

// Stolen from re-cy-cle
// lodash's `camelCase` method removes dots from the string; this breaks mobx-spine
export function snakeToCamel(s) {
  if (s.startsWith('_')) {
    return s
  }
  return s.replace(/_\w/g, (m) => m[1].toUpperCase())
}

// lodash's `snakeCase` method removes dots from the string; this breaks mobx-spine
export function camelToSnake(s) {
  return s.replace(/([A-Z])/g, ($1) => '_' + $1.toLowerCase())
}

// TODO: make separate helper files categorized by theme, e.g. "money" and "date"
// This is insane at the moment, sorry man.

export const PUBLIC_URL = process.env.NODE_ENV !== 'production' ? process.env.PUBLIC_URL : ''

// While in debug mode, customer ids can be filtered here. It speeds up page
// loading and is automatically disabled on production to prevent goldplated ids
// going live.
export const ALLOCATION_IDS = [569, 290] //[410, 414];

export const IS_DEBUG = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

// Also used by mobile, which has a window, but no location?
export const IS_STAGE = typeof window !== 'undefined' && window.location && window.location.href.includes('staging')

// Feature flags
export const FLAG_ACTIVITY_ISSUES = IS_DEBUG || IS_STAGE

export function floatToDecimal(value) {
  return value.toFixed(2).replace('.', ',')
}

// Stolen from https://gist.github.com/penguinboy/762197#gistcomment-2380871
const flatten = (object, prefix = '') => {
  return Object.keys(object).reduce((prev, element) => {
    return typeof object[element] === 'object'
      ? { ...prev, ...flatten(object[element], `${prefix}${element}.`) }
      : { ...prev, ...{ [`${prefix}${element}`]: object[element] } }
  }, {})
}

/**
 * Get list of error messages from the backend response. Typical usage:
 *
 * model.save().catch(response =>
 *     parseBackendErrorMessages(response.response.data.errors)
 * )
 */
export function parseBackendErrorMessages(errors) {
  const messages = []
  const flat = flatten(errors)

  Object.keys(flat).forEach((key) => {
    if (key.includes('.message')) {
      messages.push(flat[key])
    }
  })

  return messages
}

export function parseBackendErrorCodes(errors) {
  const codes = []
  const flat = flatten(errors)

  Object.keys(flat).forEach((key) => {
    if (key.includes('.code')) {
      codes.push(flat[key])
    }
  })

  return codes
}

export function decimalToFloat(value) {
  if (typeof value !== 'string') {
    return null
  }
  return parseFloat(value.replace(/\./g, '').replace(',', '.'))
}

export const SCREEN_WIDTH_PX = '1280px'

export const SERVER_DATE_FORMAT = 'YYYY-MM-DD'
export const SERVER_DATETIME_FORMAT = 'YYYY-MM-DD[T]HH:mm:ssZZ'
export { DATE_FORMAT }
export const DATETIME_FORMAT = 'DD-MM-YYYY HH:mm'
export const TIME_FORMAT = 'HH:mm'

export const SHORT_DATETIME_FORMAT = 'DD-MM HH:mm';
export const DATE_RANGE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss'
export const ACTION_DELAY = 300

export const LUXON_SERVER_DATE_FORMAT = 'yyyy-LL-dd';
export const LUXON_SERVER_TIME_FORMAT = 'HH:mm:ssZZZ';
export const LUXON_SERVER_DATETIME_FORMAT = `${LUXON_SERVER_DATE_FORMAT}'T'${LUXON_SERVER_TIME_FORMAT}`;

export function setMoneyForBackend(value, decimals = 2) {
  if (typeof value !== 'string') {
    return 0
  }

  const parsed = decimalToFloat(value)

  return isFinite(parsed) ? parsed * Math.pow(10, decimals) : 0
}

const moneyFormat = new Intl.NumberFormat('nl-NL', {
  style: 'currency',
  currency: 'EUR',
  currencyDisplay: 'symbol',
})

export function formatMoney(value, decimals = 2) {
  // Better money formatter, which prefixed the euro symbol. We're not yet
  // ready for this...
  //
  // There is a small but important difference with how MoneyInput formats
  // negative numbers. The negative sign must come first, and
  // Intl.NumberFormat sets the negative sign after the € sign.
  const formatted = moneyFormat
    .format(value / Math.pow(10, decimals))
    .split(' ')
    .join('')

  if (formatted.includes('-')) {
    return formatted[1] + formatted[0] + formatted.slice(2)
  }

  return formatted
}

export function getMoneyForUser(value, decimals = 2) {
  if (typeof value !== 'number') {
    return null
  }

  return (value / Math.pow(10, decimals)).toFixed(decimals).replace('.', ',')
}

// Found it weird to use money helpers on fuel surcharge, so simply
// wrap them.
export function setFuelSurchargeForBackend(value) {
  return setMoneyForBackend(value)
}

export function getFuelSurchargeForUser(value) {
  return getMoneyForUser(value)
}

export function setFactorForBackend(value) {
  return setMoneyForBackend(value)
}

export function getFactorForUser(value) {
  return getMoneyForUser(value)
}

export function getFactorInputProps() {
  return Object.assign(getMoneyInputProps(), {
    prefix: undefined,
    suffix: '%',
  })
}

// It is possible that in a <select> component, the currently selected model
// is not present in the list; either it is deleted, or the store has pagination, etc.
export function addSelectedModelInOptions(models, selectedModel) {
  const newModels = models.filter()
  if (selectedModel.id && !newModels.find((m) => m.id === selectedModel.id)) {
    newModels.push(selectedModel)
  }
  return newModels
}

// Accepts a request error, and transforms it into an array
// of notification messages.
export function formatCustomValidationErrors(err) {
  let output = []

  each(get(err, 'response.data.errors'), (errors, resource) => {
    output = output.concat(
      errors.map((e, i) => {
        return {
          key: `${resource}${i}`,
          message: e.message,
          dismissAfter: 4000,
        }
      })
    )
  })
  return output
}

export const BOOL_OPTIONS = [
  { value: 'true', text: t('form.yes') },
  { value: undefined, text: t('form.either') },
  { value: 'false', text: t('form.no') },
]

export function range(start, stop, step = 1) {
  if (stop === undefined) {
    stop = start
    start = 0
  }
  const items = []
  for (let item = start; step >= 0 ? item < stop : item > stop; item += step) {
    items.push(item)
  }
  return items
}

const NUMBER_MULTIPLIERS = [
  { amount: 1000000, suffix: 'M' },
  { amount: 1000, suffix: 'k' },
]

export function shortNumber(number) {
  let suffix = ''
  // eslint-disable-next-line
  for (const multiplier of NUMBER_MULTIPLIERS) {
    if (number >= multiplier.amount) {
      number /= multiplier.amount
      suffix = multiplier.suffix
      break
    }
  }
  return `${number.toFixed(suffix !== '' && number < 10 ? 1 : 0).replace('.', ',')}${suffix}`
}

export function formatNumber(number, digits = 1) {
  number = number.toFixed(digits)
  if (number.includes('.')) {
    let end = number.length
    while (number[end - 1] === '0') {
      end--
    }
    if (number[end - 1] === '.') {
      end--
    }
    number = number.slice(0, end)
  }
  return number
}


function getCacheKey(args) {
  const keys = []
  // eslint-disable-next-line
  for (let arg of args) {
    if (moment.isMoment(arg)) {
      arg = arg.unix()
    } else if (arg instanceof Model) {
      arg = `${arg.constructor.backendResourceName}(${arg.cid})`
    } else if (Array.isArray(arg)) {
      arg = getCacheKey(arg)
    }
    keys.push(JSON.stringify(arg))
  }
  return keys.join(',')
}

export function cached(func) {
  const cache = {}
  return function (...args) {
    const cacheKey = getCacheKey(args)
    let res = cache[cacheKey]
    if (res === undefined) {
      res = func(...args)
      cache[cacheKey] = res
    }
    return res
  }
}

export const PRINT_URL_PARAMS = {
  slug: (
    window.location.hostname.endsWith('.tracycontrol.com')
    ? window.location.hostname.slice(0, -'.tracycontrol.com'.length)
    : window.location.hostname
  ),
  app_url: '.tracycontrol.com',
};

export function regexEscape(content) {
  return content.replace(/([^A-Za-z0-9_-])/g, '\\$1')
}

export function onEnter(callback) {
  return (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      callback(e)
    }
  }
}

export function sortStore(store, fields) {
  fields = fields?.split(',') ?? []
  fields.push('id')

  const comparators = fields.map((field) => {
    let inverted = false
    if (field.startsWith('-')) {
      field = field.slice(1)
      inverted = true
    }

    const path = field.split('.').map(snakeToCamel)
    function getValue(value) {
      // eslint-disable-next-line
      for (const key of path) {
        value = value[key]
      }
      if (value instanceof Model) {
        value = value.id
      }
      return value
    }

    function comparator(lmodel, rmodel) {
      const lvalue = getValue(inverted ? rmodel : lmodel)
      const rvalue = getValue(inverted ? lmodel : rmodel)

      if (moment.isMoment(lvalue)) {
        return lvalue.diff(rvalue)
      } else if (typeof lvalue === 'string') {
        return lvalue.localeCompare(rvalue)
      } else {
        return lvalue - rvalue
      }
    }

    return comparator
  })

  function combinedComparator(lmodel, rmodel) {
    // eslint-disable-next-line
    for (const comparator of comparators) {
      const comparison = comparator(lmodel, rmodel)
      if (comparison !== 0) {
        return comparison
      }
    }
    return 0
  }

  store.models.replace(store.models.slice().sort(combinedComparator))
}

export function serialNumberFormatToRegex(serialNumberFormat) {
  return new RegExp(`^${
    serialNumberFormat
    .map((part) => {
      switch (part.type) {
        case 'text':
          return regexEscape(part.content)
        case 'code':
          return `${part.expand ? `([${regexEscape(part.alphabet.slice(1))}][${regexEscape(part.alphabet)}]*)?` : ''}[${regexEscape(part.alphabet)}]{${part.digits}}`
        case 'date':
          if (part.part === 'isoweekday' && part.format === 'd') {
            return '[1-7]'
          } else if (part.format === 'text') {
            return part.names.map(regexEscape).join('|')
          } else {
            return {
              yyyy: '\\d{4}',
              yy: '\\d{2}',
              mm: '0[1-9]|1[0-2]',
              m: '[1-9]|1[0-2]',
              dd: '0[1-9]|[1-2]\\d|3[0-1]',
              d: '[1-9]|[1-2]\\d|3[0-1]',
              ww: '0[1-9]|[1-4]\\d|5[0-3]',
              w: '[1-9]|[1-4]\\d|5[0-3]',
            }[part.format]
          }
        case 'anything':
          return '.*'
        default:
          throw new Error(`unknown type: ${part.type}`)
      }
    })
    .join('')
  }$`)
}
