import React, {Component} from "react";
import {Step} from "../../../../store/Step";
import {Grid, Container, Label} from "semantic-ui-react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {t} from "../../../../i18n";
import TargetNumberInputWithChildren from "../../../../component/Target/TargetNumberInputWithChildren";
import StepTimePreview from "./StepTimePreview";

interface StepProcessTimingProps {
    step: Step;
}

const ProcessTimingExplanationText = styled(Container)`
    background-color: #F5F5F5;
    padding: 16px;

`;

const OverlayLabel = styled(Label)`
    margin-left: -48px !important;
`;


const GridRowWithDoubleColumn = styled(Grid.Row)`
    // Don't change this.
  .fourtypercent.fourtypercent.fourtypercent.fourtypercent.fourtypercent.fourtypercent.fourtypercent.fourtypercent.fourtypercent.fourtypercent  {
    width:40% !important;
  }
`;

@observer
export default class StepProcessTiming extends Component<StepProcessTimingProps> {


    render() {
        const {step} = this.props;

        const numberInputContentProps = {
            labelPosition: 'left',
            children: [
                <input/>,
                <OverlayLabel basic>min</OverlayLabel>
            ]
        }


        return (
            <Grid>
                <GridRowWithDoubleColumn columns={5}>
                    <Grid.Column>
                        <TargetNumberInputWithChildren
                            target={step}
                            name="delayMinutes"
                            contentProps={numberInputContentProps}
                        />

                        <ProcessTimingExplanationText>
                            <p>
                                {t('step.field.delayMinutes.info')}
                            </p>
                            <p>
                                {t('step.field.delayMinutes.infoElaborate')}
                            </p>
                        </ProcessTimingExplanationText>
                    </Grid.Column>
                    <Grid.Column>
                        <TargetNumberInputWithChildren
                            target={step}
                            name="setupMinutes"
                            contentProps={numberInputContentProps}
                        />
                        <ProcessTimingExplanationText>
                            <p>
                                {t('step.field.setupMinutes.info')}
                            </p>
                            <p>
                                {t('step.field.setupMinutes.infoElaborate')}
                            </p>
                        </ProcessTimingExplanationText>
                    </Grid.Column>
                    <Grid.Column>
                        <TargetNumberInputWithChildren
                            target={step}
                            name="workMinutes"
                            contentProps={numberInputContentProps}
                        />
                        <ProcessTimingExplanationText>
                            <p>
                                {t('step.field.workMinutes.info')}
                            </p>
                            <p>
                                {t('step.field.workMinutes.infoElaborate')}
                            </p>
                        </ProcessTimingExplanationText>
                    </Grid.Column>
                    <Grid.Column width={6} className="fourtypercent">
                        <StepTimePreview
                            step={step}
                        />
                    </Grid.Column>
                </GridRowWithDoubleColumn>
            </Grid>
        )
    }
}

