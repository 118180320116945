import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ArticleType } from 'store/ArticleType'
import {Warehouse} from "./Warehouse";

export class StockDeficit extends Model {
  static backendResourceName = 'stock_deficit'

  @observable id = null
  @observable quantity = 0

  relations() {
    return {
      articleType: ArticleType,
      warehouse: Warehouse,
    }
  }
}

export class StockDeficitStore extends Store {
  static backendResourceName = 'stock_deficit'
  Model = StockDeficit
}
