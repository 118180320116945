import {Model} from 'store/Base';
import {Component} from "react";
import {Step} from "../../store/Step";
import {Batch} from "../../store/Batch";
import {ProductionRequest} from "../../store/ProductionRequest";
import { Operator } from 'store/Operator';
/**
 * Defines the various interface needed for adding a new step
 */


/**
 * Interface for the properties that are passed on to the MultiStepVioew
 */
export interface PerformMultiStepProps {
    step?: Step;
    steps?: Map<Step, ProductionRequest[]>;
    batches: Batch[];

    operator: Operator;

    // What should be done after performing a step
    afterPerform: () => void;

}

export class PerformMultiStepComponent extends Component<PerformMultiStepProps> {

}




export interface ProcessStep {
    /**
     * The step model
     */
    model: () => typeof Model;
    multiStepView?: () => (typeof PerformMultiStepComponent)

}
