import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { NestRequest } from '../store/Workstation/NestRequest'
import { Duration } from 'luxon'

/**
 * Displays the details of the nest which are listed in the NestRequest
 * The default render will be compatible with a perform view, where it slots into an existing <tbody> element;
 * use the helper function below this class to render it as a standalone table
 */
export default class NestRequestDetails extends Component {
  static propTypes = {
    nestRequest: PropTypes.instanceOf(NestRequest).isRequired
  }

  render() {
    const { nestRequest } = this.props
    const details = ['material', 'thickness', 'dimensions', 'runTime']
    const duration = Duration.fromMillis(nestRequest.runTime * 1000)
    const runTime = duration.toFormat('hh:mm:ss')

    return (
      <tr>
        <td>
          <b>{t('nestRequest.label')}</b>
        </td>
        <td>
          <tbody>
            {details.map(detail => (
              <tr>
                <td style={{'min-width':'100px'}}>
                  <b>{t(`nestRequest.details.${detail}`)}</b>
                </td>
                <td data-test-nest-request-detail={detail}>
                  {detail === 'runTime' ? runTime : nestRequest[detail]}
                </td>
              </tr>
            ))}
          </tbody>
        </td>
      </tr>
    )
  }
}


// function getNestRequestDetailsTable(nestRequest) {
//   return (
//     <tbody>
//       <NestRequestDetails
//         nestRequest={nestRequest}
//       />
//     </tbody>
//   )
// }