import { observable } from 'mobx'
import { Store } from 'store/Base';
import { CarrierIntegration } from './CarrierIntegration';
import { Integration } from './Integration';
import { ShippingUnitStore } from './ShippingUnit';

export class JanKredietIntegration extends CarrierIntegration {
    static backendResourceName = 'jan_krediet_integration';
    static omitFields = ['active'];

    @observable id = null;
    @observable active = false;

    @observable apiKey = "";
    @observable shippingLabelTemplate = "";
    @observable senderName = "";
    @observable senderAddress = "";
    @observable senderHouseNumber = "";
    @observable senderCountryCode = "";
    @observable senderZipCode = "";
    @observable senderCity = "";

    relations() {
      return {
        integration: Integration,
        shippingUnits: ShippingUnitStore,
      }
    }

}

export class JanKredietIntegrationStore extends Store {
    static backendResourceName = 'jan_krediet_integration';
    Model = JanKredietIntegration;
  }