import {Table} from "semantic-ui-react";
import React, {Component} from "react";
import {ScaleTime} from "d3-scale";
import {observer} from "mobx-react";
import {ProcessData, StepDatum} from "./Interface";
import {FullHeightSvg} from "./Components";
import {TimingIndications} from "./TimingIndications";
import StepTimeBar from "./StepTimeBar";
import {FinishBar} from "./FinishBar";

interface StepRowProps {
    scaleX: ScaleTime<number, number>;
    stepDatum: StepDatum;

    processData: ProcessData;
}


// Height of the progress bar;
const PROGRESS_BAR_HEIGHT = 20;
const ROW_HEIGHT = 50;

@observer
export default class StepRow extends Component<StepRowProps> {


    render() {
        const {scaleX, stepDatum, processData} = this.props;

        const width = scaleX.range()[1] - scaleX.range()[0];

        const startPlannedX = scaleX(stepDatum.plannedStart);
        const endPlannedX = scaleX(stepDatum.plannedEnd);


        return (
            <Table.HeaderCell>
                <FullHeightSvg width={width} height={45} viewBox={`0 0 1000 ${ROW_HEIGHT}`}>
                    <rect
                        x={startPlannedX}
                        width={endPlannedX - startPlannedX}
                        height={50}
                        fill={"#BDBDBD"}
                    />
                    {processData.erpPlannedEndAt && (
                        <FinishBar
                            scaleX={scaleX}
                            date={processData.erpPlannedEndAt}
                            rowHeight={ROW_HEIGHT}
                        />
                    )}

                    {stepDatum.actualStart && (
                        <StepTimeBar
                            actualEnd={stepDatum.actualEnd}
                            actualStart={stepDatum.actualStart}
                            plannedEnd={stepDatum.plannedEnd}
                            scaleX={scaleX}
                            rowHeight={ROW_HEIGHT}
                            barHeight={PROGRESS_BAR_HEIGHT}
                        />
                    )}

                    <TimingIndications
                        scaleX={scaleX}
                    />


                </FullHeightSvg>
            </Table.HeaderCell>
        )
    }

}
