import {computed, observable} from 'mobx'
import { Model, Store } from 'store/Base'
import {Integration} from "./Integration";
import {sum} from "lodash";
import { Performance } from './Performance'
import { CarrierRequestRowStore } from './CarrierRequestRow'
import { CarrierRequestDocumentStore } from './CarrierRequestDocument'

export class CarrierRequest extends Model {
  static backendResourceName = 'carrier_request'

  @observable id = null
  @observable recipientName = '';
  @observable recipientStreet = '';
  @observable recipientHouseNumber = '';
  @observable recipientPostalCode = '';
  @observable recipientCity = '';
  @observable recipientCountry = ''
  @observable recipientEmail = '';
  @observable recipientPhoneNumber = '';
  @observable number = '';

  /**
   * Calculate total weight of this request
   */
  @computed get totalWeight() {
    return sum(this.carrierRequestRows.map(row => row.weigth))
  }

  getShippingLabels() {
    return this.wrapPendingRequestCount(this.api.get(`${this.url}shipping_labels/`))
  }

  relations() {
    return {
      carrier: Integration,
      carrierRequestRows: CarrierRequestRowStore,
      documents: CarrierRequestDocumentStore,
      performance: Performance,
    }
  }
}

export class CarrierRequestStore extends Store {
  Model = CarrierRequest
  static backendResourceName = 'carrier_request'
}
